import React, { Component } from 'react';
import { Redirect } from 'react-router';

type Props = {
  message: string;
};

type State = {
  hasError: boolean;
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    // TODO: Log the error to the server
  }

  render() {
    if (this.state.hasError) {
      return <Redirect to={'/fejl'} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
