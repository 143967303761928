import * as AuthService from 'security/AuthService';
import config from '../config/config';
const { apiUrl } = config;
let refreshingPromise: Promise<Response>;

// Create customFetch function for handling re-authorization
export const customFetch = async (
  uri: string,
  options: any
): Promise<Response> => {
  refreshingPromise = null;

  const initialResponse = await fetch(uri, options);
  const responseClone = initialResponse.clone();
  const json = await initialResponse.json();
  if (
    json &&
    json.errors &&
    json.errors[0] &&
    json.errors[0].extensions &&
    json.errors[0].extensions.code === 'authorization'
  ) {
    if (!refreshingPromise) {
      refreshingPromise = fetch(
        `${apiUrl}/api/authentication/refreshaccesstoken`,
        {
          method: 'POST',
          credentials: 'same-origin'
        }
      );
    }

    const refreshResponse = await refreshingPromise;
    if (refreshResponse.status === 400) {
      AuthService.removeUser();
      window.location.href = '';
      return void 0;
    } else {
      const refreshJson = await refreshResponse.json();
      AuthService.setUser(refreshJson);
    }
    return fetch(uri, options);
  }
  return responseClone;
};
