let matomoEvent = location => {
  //@ts-ignore
  window._paq.push(['setReferrerUrl', window.location.href]);
  //@ts-ignore
  window._paq.push(['setCustomUrl', location.pathname]);
  //@ts-ignore
  window._paq.push(['setDocumentTitle', location.pathname]);

  // remove all previously assigned custom variables, requires Matomo (formerly Piwik) 3.0.2
  //@ts-ignore
  window._paq.push(['deleteCustomVariables', 'page']);
  //@ts-ignore
  window._paq.push(['setGenerationTimeMs', 0]);
  //@ts-ignore
  window._paq.push(['trackPageView']);

  // make Matomo aware of newly added content
  //@ts-ignore
  let content = document.getElementById('root');
  //@ts-ignore
  window._paq.push(['MediaAnalytics::scanForMedia', content]);
  //@ts-ignore
  window._paq.push(['FormAnalytics::scanForForms', content]);
  //@ts-ignore
  window._paq.push(['trackContentImpressionsWithinNode', content]);
  //@ts-ignore
  window._paq.push(['enableLinkTracking']);
};
export default matomoEvent;
