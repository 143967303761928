// import './polyfill';
import 'babel-polyfill'; // TODO perf: change this to the above ^
import 'react-app-polyfill/ie11'; // TODO perf: change this to the above ^
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { ApolloProvider } from 'react-apollo';
import { client } from 'graphql/client';
import { Provider } from 'react-redux';
import store from 'store';
import { BrowserRouter as Router } from 'react-router-dom';

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
