import React, { Component } from 'react';
import {
  BreadcrumbsStudentQueryProps,
  withBreadcrumbsStudentQuery
} from 'graphql/types';
import { RouteComponentProps } from 'react-router';
import { MatchParams } from 'config/routes';
import Breadcrumb from '../Breadcrumb/Breadcrumb';

type Props = {} & BreadcrumbsStudentQueryProps &
  RouteComponentProps<
    Pick<MatchParams, 'uniLoginId' | 'schoolClassId' | 'institutionId'>
  >;

class StudentBreadcrumbContainer extends Component<Props, any> {
  render() {
    const {
      data: { loading, error, student },
      match
    } = this.props;
    if (loading || error) {
      return <Breadcrumb breadcrumb={match.params['uniLoginId']} />;
    } else {
      return <Breadcrumb breadcrumb={student.name} />;
    }
  }
}

export default withBreadcrumbsStudentQuery<Props>({
  options: props => ({
    variables: {
      uniLoginId: props.match.params['uniLoginId'],
      institutionId: props.match.params['institutionId'],
      schoolClassId: props.match.params['schoolClassId']
    }
  })
})(StudentBreadcrumbContainer);
