import React, { Suspense, LazyExoticComponent } from 'react';
import { Spin } from 'antd';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';
import styles from './WaitingComponent.module.scss';

export default (Component: LazyExoticComponent<any>) => (props: any) => (
  <ErrorBoundary message={'Noget gik galt. Forsøg at genindlæse siden.'}>
    <Suspense
      fallback={
        <div className={styles.spin}>
          <Spin size={'large'} />
        </div>
      }
    >
      <Component {...props} />
    </Suspense>
  </ErrorBoundary>
);
