import { createStore, Store } from 'redux';
import rootReducer from './reducers';
import { ActiveObjectiveType } from './actions/objective';
import { ActionTypes as ActiveObjectiveActionTypes } from './reducers/activeObjective';
import { ActivePreschoolTestType } from './actions/preschoolTest';
import { ActionTypes as ActivePreschoolTestActionTypes } from './reducers/activePreschoolTest';

export interface IGlobalState {
  activeObjective: ActiveObjectiveType;
  activePreschoolTest: ActivePreschoolTestType;
}

type ActionTypes = ActiveObjectiveActionTypes | ActivePreschoolTestActionTypes;

let store: Store<IGlobalState, ActionTypes>;

// TODO "1" er id'et på den aller første sprogprøve
const initialState = {
  activePreschoolTest: 1
};

const isProduction = process.env.NODE_ENV === 'production';

if (isProduction) {
  store = createStore(rootReducer, initialState);
} else {
  store = createStore(
    rootReducer,
    initialState,
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
      (window as any).__REDUX_DEVTOOLS_EXTENSION__()
  );
}

export default store;
