import {
  SetActiveObjectiveAction,
  SetActiveObjectiveType
} from 'store/actions/objective';
import { Action } from 'redux';

export type ActionTypes = SetActiveObjectiveAction;

const activeObjective = (state: string = null, action: ActionTypes) => {
  switch (action.type) {
    case SetActiveObjectiveType:
      return action.payload;
    default:
      return state;
  }
};

export default (state: any, action: Action<string>) =>
  activeObjective(state, action as ActionTypes);
