import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import { MatchParams } from 'config/routes';
import Breadcrumb from '../Breadcrumb/Breadcrumb';

type Props = {} & RouteComponentProps<Pick<MatchParams, 'schoolClassId'>>;

class SchoolClassBreadcrumbContainer extends Component<Props, any> {
  render() {
    return <Breadcrumb breadcrumb={this.props.match.params['schoolClassId']} />;
  }
}

export default SchoolClassBreadcrumbContainer;
