import { Action, createAction } from 'store/utils/utils';
import { PreschoolTest } from 'graphql/types';

export type ActivePreschoolTestType = PreschoolTest['id'];

/**
 * Action types
 */
export const SetActivePreschoolTestType =
  '@@sprogvurderinger/SET_ACTIVE_PRESCHOOLTEST';

/**
 * Actions
 */
export type SetActivePreschoolTestAction = Action<
  typeof SetActivePreschoolTestType,
  ActivePreschoolTestType
>;

/**
 * Action creators
 */
export const setActivePreschoolTest = (
  preschoolTestId: ActivePreschoolTestType
): SetActivePreschoolTestAction => {
  return createAction(SetActivePreschoolTestType, preschoolTestId);
};
