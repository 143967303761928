import React, { lazy } from 'react';
import WaitingComponent from 'components/WaitingComponent/WaitingComponent';
import { Redirect, Route, RouteComponentProps } from 'react-router';
import SchoolClassBreadcrumb from 'components/Breadcrumbs/SchoolClassBreadcrumb/SchoolClassBreadcrumb.container';
import StudentBreadcrumb from 'components/Breadcrumbs/StudentBreadcrumb/StudentBreadcrumb.container';
import MaterialBreadcrumb from 'components/Breadcrumbs/MaterialBreadcrumb/MaterialBreadcrumb.container';
import * as AuthService from 'security/AuthService';
import queryString from 'query-string';
import ErrorPage from 'pages/ErrorHandling/Error'; // Should not be lazily imported, as for network errors we cannot show the error page if it needs to be fetched.

const Unauthorized = lazy(() => import('../pages/ErrorHandling/Unauthorized'));
const NotFound = lazy(() => import('../pages/ErrorHandling/NotFound'));

const ClassResult = lazy(() =>
  import('../pages/ClassResult/ClassResult.container')
);
const MyClasses = lazy(() => import('../pages/MyClasses/MyClasses.container'));
const ClassOverview = lazy(() =>
  import('../pages/ClassOverview/ClassOverview.container')
);
const StudentOverview = lazy(() =>
  import('../pages/StudentOverview/StudentOverview.container')
);
const StudentResult = lazy(() =>
  import('../pages/StudentResult/StudentResult.container')
);
const Materials = lazy(() => import('../pages/Materials/Materials.container'));
const Material = lazy(() => import('../pages/Material/Material.container'));

const LandingPage = lazy(() =>
  import('../pages/LandingPage/LandingPage.container')
);

const LogOut = lazy(() => import('../pages/LogOut/LogOut'));

const SSO = WaitingComponent(lazy(() => import('../pages/SSO/SSO')));

export type RouteDefinition = {
  path: string;
  component?: any;
  breadcrumb: string | React.ReactNode | null | undefined;
  routes?: RouteDefinition[];
  showInNav?: boolean;
  navName?: string;
  private?: boolean;
  exact?: boolean;
};

export type MatchParams = {
  institutionId: string;
  schoolClassId: string;
  uniLoginId: string;
  materialId: string;
};

export const routes: RouteDefinition[] = [
  {
    path: '/uautoriseret',
    component: WaitingComponent(Unauthorized),
    breadcrumb: null
  },
  {
    path: '/fejl',
    component: ErrorPage,
    breadcrumb: null
  },
  {
    path: '/materialer',
    component: WaitingComponent(Materials),
    breadcrumb: 'Materialebank',
    showInNav: true,
    navName: 'Materialebank',
    private: true,
    exact: true
  },
  {
    path: '/materialer/:materialId',
    component: WaitingComponent(Material),
    breadcrumb: MaterialBreadcrumb,
    private: true
  },
  {
    path: '/klasser',
    component: WaitingComponent(MyClasses),
    breadcrumb: 'Mine klasser',
    showInNav: true,
    navName: 'Mine klasser',
    private: true
  },
  {
    path: '/institution',
    breadcrumb: null
  },
  { path: '/institution/:institutionId', breadcrumb: null },
  { path: '/institution/:institutionId/klasser', breadcrumb: 'Mine klasser' },
  {
    path: '/institution/:institutionId/klasser/:schoolClassId',
    component: WaitingComponent(ClassOverview),
    breadcrumb: SchoolClassBreadcrumb,
    private: true,
    exact: true
  },
  {
    path: '/institution/:institutionId/klasser/:schoolClassId/resultat',
    component: WaitingComponent(ClassResult),
    breadcrumb: 'Resultat',
    private: true
  },
  {
    path: '/institution/:institutionId/klasser/:schoolClassId/elever',
    breadcrumb: null
  },
  {
    path:
      '/institution/:institutionId/klasser/:schoolClassId/elever/:uniLoginId',
    component: WaitingComponent(StudentOverview),
    breadcrumb: StudentBreadcrumb,
    private: true,
    exact: true
  },
  {
    path:
      '/institution/:institutionId/klasser/:schoolClassId/elever/:uniLoginId/resultat',
    component: WaitingComponent(StudentResult),
    breadcrumb: 'Resultat',
    private: true
  },
  {
    path: '/logud',
    component: WaitingComponent(LogOut),
    breadcrumb: null
  },
  {
    path: '/',
    component: WaitingComponent(LandingPage),
    breadcrumb: null,
    exact: true
  },
  {
    path: null,
    component: WaitingComponent(NotFound),
    breadcrumb: null
  }
];

const containsSSOParameters = (search: string): boolean => {
  const parsed = queryString.parse(search);
  if (!parsed) {
    return false;
  }

  return Object.keys(parsed).includes('ltoken');
};

const PrivateRoute = ({ component: Component, ...props }: any) => {
  return (
    <Route
      {...props}
      render={(innerProps: RouteComponentProps) => {
        if (AuthService.isAuthenticated()) {
          return <Component {...innerProps} />;
        } else if (containsSSOParameters(innerProps.location.search)) {
          return <SSO />;
        } else {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { returnUrl: innerProps.location.pathname }
              }}
            />
          );
        }
      }}
    />
  );
};

export default function RouteWithSubRoutes(route: RouteDefinition): any {
  const Component = route.component;
  if (route.private) {
    return (
      <PrivateRoute
        exact={route.exact}
        path={route.path}
        component={Component}
      />
    );
  }

  return (
    <Route
      exact={route.exact}
      path={route.path}
      render={(props: RouteComponentProps) => {
        if (containsSSOParameters(props.location.search)) {
          return <SSO />;
        }
        return <Component {...props} routes={route.routes} />;
      }}
    />
  );
}
