import React, { Component } from 'react';
import { Layout, Typography, Col, Row } from 'antd';
import classnames from 'classnames';
import styles from './Footer.module.scss';
import Container from '../Container/Container';
import logo from 'assets/icon/UVM_black.svg';

const { Footer: LayoutFooter } = Layout;
const { Title, Text } = Typography;

const defaultProps = Object.freeze({
  external: false,
  informationLinks: [
    {
      title: 'Om sprogprøven i børnehaveklassen',
      href: 'https://uvm.dk/sprogproever'
    },
    {
      title: 'Vejledning',
      href:
        'https://www.uvm.dk/folkeskolen/laering-og-laeringsmiljoe/sprogproever/regler-og-vejledning'
    },
    {
      title: 'Spørgsmål og svar',
      href:
        'https://viden.stil.dk/pages/viewpage.action?pageId=61865993'
    }
  ],
  supportLinks: [
    {
      title: 'Brugervejledning',
      href: 'https://viden.stil.dk/pages/viewpage.action?pageId=61865993'
    }
  ],
  privacyLinks: [
    {
      title:
        'Få svar på dine spørgsmål om vores datasikkerhed og dine rettigheder i forhold til indhentelse, opbevaring og deling af personoplysninger',
      href:
        'https://www.uvm.dk/-/media/filer/uvm/udd/folke/pdf20/jul/200706-vejledning-om-gdpr-ift-brug-af-www-sprogproever-dk.pdf'
    }
  ],
  stilSupportLink: {
    title: 'Skriv til supporten',
    link: 'https://jira.stil.dk/servicedesk/customer/portal/5/group/11'
  }
});

type LandingPageLink = {
  title: string;
  href: string;
};

type Props = {} & typeof defaultProps;

class Footer extends Component<Props, any> {
  static readonly defaultProps = defaultProps;

  render() {
    const {
      external,
      informationLinks,
      supportLinks,
      privacyLinks,
      stilSupportLink
    } = this.props;

    return (
      <LayoutFooter
        className={classnames(styles.footer, {
          [styles.externalFooter]: external
        })}
      >
        <InternalContainer external={external}>
          <div className={styles.linksContainer}>
            <Row type={'flex'} gutter={8}>
              <Col xs={12} md={external ? 8 : 6}>
                <Title level={3}>Information</Title>
                <ul className={styles.list}>
                  {informationLinks.map(
                    (link: LandingPageLink, index: number) => (
                      <li key={index}>
                        <a href={link.href} target={'_blank'}>
                          <Text underline={true}>{link.title}</Text>
                        </a>
                      </li>
                    )
                  )}
                </ul>
              </Col>
              <Col xs={12} md={external ? 8 : 6}>
                <Title level={3}>Support</Title>
                <ul className={styles.list}>
                  {supportLinks.map((link: LandingPageLink, index: number) => (
                    <li key={index}>
                      <a href={link.href} target={'_blank'}>
                        <Text underline={true}>{link.title}</Text>
                      </a>
                    </li>
                  ))}
                </ul>
              </Col>
              <Col xs={12} md={external ? 8 : 6}>
                <Title level={3}>Personoplysninger og databeskyttelse</Title>
                <ul className={styles.list}>
                  {privacyLinks.map((link: LandingPageLink, index: number) => (
                    <li key={index}>
                      <a href={link.href} target={'_blank'}>
                        <Text underline={true}>{link.title}</Text>
                      </a>
                    </li>
                  ))}
                </ul>
              </Col>
              {!external && (
                <Col xs={12} md={6}>
                  <img className={styles.logo} src={logo} alt={'Logo'} />
                </Col>
              )}
            </Row>
          </div>
          <div
            className={
              external
                ? styles.contactInfoContainer
                : styles.contactInfoContainerInternal
            }
          >
            <Row>
              <Title level={4}>STIL Support</Title>
              <Col xs={12} md={external ? 8 : 6}>
                <div>Man - tor kl. 8.00 - 16.00</div>
                <div>Fre kl. 8.00 - 14.00</div>
              </Col>
              <Col xs={12} md={external ? 8 : 6}>
                <div>Tlf. 7021 2155</div>
              </Col>
              <Col xs={12} md={external ? 8 : 6}>
                <a href={stilSupportLink.link} target={'_blank'}>
                  <Text underline={true}>{stilSupportLink.title}</Text>
                </a>
              </Col>
            </Row>
          </div>
        </InternalContainer>
      </LayoutFooter>
    );
  }
}

const InternalContainer = (props: any) => {
  if (!props.external) {
    return <Container>{props.children}</Container>;
  } else return props.children;
};

export default Footer;
