import React, { Component } from 'react';
import { RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import RouteWithSubroutes, { RouteDefinition, routes } from './config/routes';
import matomoEvent from './config/matomo';

type Props = {} & RouteComponentProps;

class App extends Component<Props> {
  constructor(props: Props) {
    super(props);

    this.props.history.listen(location => {
      matomoEvent(location);
    });
  }

  render() {
    return (
      <Switch>
        {routes
          .filter((route: RouteDefinition) => !!route.component)
          .map((route: RouteDefinition, index: number) => (
            <RouteWithSubroutes key={index} {...route} />
          ))}
      </Switch>
    );
  }
}

export default withRouter(App);
