import React, { Component } from 'react';
import { Layout, Menu } from 'antd';
import styles from './Header.module.scss';
import Brand from './Brand/Brand';
import UserInfo from './UserInfo/UserInfo.container';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import { RouteDefinition, routes } from 'config/routes';
import uvmLogo from 'assets/icon/UVM_black.svg';
import MediaQuery from 'react-responsive';
import { slide as BurgerMenu } from 'react-burger-menu';

type Props = {
  external: boolean;
} & RouteComponentProps;

const { Header: LayoutHeader } = Layout;

class Header extends Component<Props, any> {
  private navRoutes = routes.filter(
    (route: RouteDefinition) => route.showInNav
  );

  render() {
    return (
      <LayoutHeader className={styles.header}>
        <Brand title={'Sprogprøver'} />
        <div className={styles.menuContainer}>
          {!this.props.external ? (
            <>
              <MediaQuery minWidth={1224}>
                <Menu
                  mode={'horizontal'}
                  className={styles.menu}
                  selectedKeys={this.getCurrentRoute()}
                >
                  <Menu.Item key={'nyheder'} className={styles.menuItem}>
                    <a
                      href={'https://uvm.dk/sprogproever'}
                      className={styles.menuItemTitle}
                      target={'_blank'}
                      rel={'external'}
                    >
                      Nyheder
                    </a>
                  </Menu.Item>
                  {this.navRoutes.map((route: RouteDefinition) => (
                    <Menu.Item key={route.navName} className={styles.menuItem}>
                      <NavLink to={route.path} className={styles.menuItemTitle}>
                        {route.navName}
                      </NavLink>
                    </Menu.Item>
                  ))}
                  <Menu.Item key={'vejledning'} className={styles.menuItem}>
                    <a
                      href={
                        'https://www.uvm.dk/folkeskolen/laering-og-laeringsmiljoe/sprogproever/regler-og-vejledning'
                      }
                      className={styles.menuItemTitle}
                      target={'_blank'}
                      rel={'external'}
                    >
                      Vejledning
                    </a>
                  </Menu.Item>
                </Menu>
                <UserInfo />
              </MediaQuery>
              <MediaQuery maxWidth={1223}>
                <BurgerMenu right width={200} className={styles.burgerMenu}>
                  <a
                    href={'https://uvm.dk/sprogproever'}
                    className={styles.menuItemTitle}
                    target={'_blank'}
                    rel={'external'}
                  >
                    Nyheder
                  </a>
                  {this.navRoutes.map((route: RouteDefinition) => (
                    <NavLink
                      key={route.navName}
                      to={route.path}
                      className={styles.menuItemTitle}
                    >
                      {route.navName}
                    </NavLink>
                  ))}
                  <a
                    href={
                      'https://www.uvm.dk/folkeskolen/elevplaner-nationale-test--trivselsmaaling-og-sprogproever/sprogproever/regler-og-vejledning'
                    }
                    className={styles.menuItemTitle}
                    target={'_blank'}
                    rel={'external'}
                  >
                    Vejledning
                  </a>
                  <NavLink to={'/logud'} className={styles.menuItemTitle}>
                    Log ud
                  </NavLink>
                </BurgerMenu>
              </MediaQuery>
            </>
          ) : (
            <Brand logo={uvmLogo} title={''} className={styles.uvmLogo} />
          )}
        </div>
      </LayoutHeader>
    );
  }

  private getCurrentRoute(): string[] | undefined {
    const {
      location: { pathname }
    } = this.props;
    const currentRoute = this.navRoutes.find(
      (route: RouteDefinition) => route.path === pathname
    );
    return currentRoute ? [currentRoute.navName!] : undefined;
  }
}

export default withRouter(Header);
