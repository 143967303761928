const LOCAL_STORAGE_KEY = 'authenticatedUser';

export const isAuthenticated = (): boolean => {
  return !!localStorage.getItem(LOCAL_STORAGE_KEY);
};

export const setUser = (user: object) => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(user));
};

export const removeUser = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEY);
};

export const getUsername = (): string | null => {
  if (!isAuthenticated()) return null;
  const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  return user.userId;
};

export const getToken = (): string | null => {
  if (!isAuthenticated()) return null;
  const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  return user.token;
};
