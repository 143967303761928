import React, { Component } from 'react';

type Props = {
  breadcrumb: string;
};

class Breadcrumb extends Component<Props, any> {
  render() {
    return <span>{this.props.breadcrumb}</span>;
  }
}

export default Breadcrumb;
