import React, { Component } from 'react';
import {
  BreadcrumbsMaterialQueryProps,
  withBreadcrumbsMaterialQuery
} from 'graphql/types';
import { RouteComponentProps } from 'react-router';
import { MatchParams } from 'config/routes';
import Breadcrumb from '../Breadcrumb/Breadcrumb';

type Props = {} & BreadcrumbsMaterialQueryProps &
  RouteComponentProps<Pick<MatchParams, 'materialId'>>;

class MaterialBreadcrumbContainer extends Component<Props, any> {
  render() {
    const {
      data: { loading, error, material },
      match
    } = this.props;
    if (loading || error || !material) {
      return <Breadcrumb breadcrumb={match.params['materialId']} />;
    } else {
      return <Breadcrumb breadcrumb={material.title} />;
    }
  }
}

export default withBreadcrumbsMaterialQuery<Props>({
  options: props => ({
    variables: { materialId: Number(props.match.params['materialId']) }
  })
})(MaterialBreadcrumbContainer);
