import React, { Component } from 'react';
import UserInfo from './UserInfo';
import * as AuthService from 'security/AuthService';

type Props = {};

class UserInfoContainer extends Component<Props, any> {
  render() {
    const username = AuthService.getUsername();
    return <UserInfo username={username} />;
  }
}

export default UserInfoContainer;
