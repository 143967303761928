import {
  SetActivePreschoolTestAction,
  SetActivePreschoolTestType
} from 'store/actions/preschoolTest';
import { Action } from 'redux';

export type ActionTypes = SetActivePreschoolTestAction;

const activePreschoolTest = (state: string = null, action: ActionTypes) => {
  switch (action.type) {
    case SetActivePreschoolTestType:
      return action.payload;
    default:
      return state;
  }
};

export default (state: any, action: Action<string>) =>
  activePreschoolTest(state, action as ActionTypes);
