import React, { Component } from 'react';
import { Typography } from 'antd';
import styles from './Brand.module.scss';
import logo from 'assets/images/logo.png';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
const { Title } = Typography;

type Props = {
  title: string;
  logo: any;
  className?: string;
};

class Brand extends Component<Props, any> {
  static defaultProps = {
    logo: logo
  };
  render() {
    return (
      <div className={styles.brand}>
        <img
          className={classnames(styles.logo, this.props.className)}
          src={this.props.logo}
          alt={'Logo'}
        />
        <Title level={4} className={styles.title}>
          <Link to={'/'}>{this.props.title}</Link>
        </Title>
      </div>
    );
  }
}

export default Brand;
