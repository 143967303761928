import React, { Component } from 'react';
import PageSkeleton from '../PageSkeleton';
import Jumbotron from 'components/Jumbotron/Jumbotron';
import preLoginImage from 'assets/images/student-overview-background.jpg';

class Error extends Component {
  render() {
    return (
      <PageSkeleton external={true}>
        <Jumbotron
          title={'Der er sket en fejl'}
          subtitle={'Gå venligst til forsiden'}
          backgroundImage={preLoginImage}
        />
      </PageSkeleton>
    );
  }
}

export default Error;
