import { Action, createAction } from 'store/utils/utils';
import { Objective } from 'graphql/types';

export type ActiveObjectiveType = Objective['id'];

/**
 * Action types
 */
export const SetActiveObjectiveType = '@@sprogvurderinger/SET_ACTIVE_OBJECTIVE';

/**
 * Actions
 */
export type SetActiveObjectiveAction = Action<
  typeof SetActiveObjectiveType,
  ActiveObjectiveType
>;

/**
 * Action creators
 */
export const setActiveObjective = (
  objectiveId: ActiveObjectiveType
): SetActiveObjectiveAction => {
  return createAction(SetActiveObjectiveType, objectiveId);
};
