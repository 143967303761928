import React, { Component } from 'react';
import { Typography, Avatar } from 'antd';
import styles from './UserInfo.module.scss';
import { Link } from 'react-router-dom';
const { Paragraph, Text } = Typography;

type Props = {
  username: string;
};

class UserInfo extends Component<Props, any> {
  render() {
    const { username } = this.props;
    return (
      <div className={styles.container}>
        <div>
          <Paragraph ellipsis={true} className={styles.username}>
            {username}
          </Paragraph>
          <Link to={'/logud'}>
            <Text className={styles.logud}>Log ud</Text>
          </Link>
        </div>
        <div className={styles.avatarContainer}>
          <Avatar icon={'user'} size={'large'} />
        </div>
      </div>
    );
  }
}

export default UserInfo;
