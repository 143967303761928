import React, { Component } from 'react';
import { Typography, Input } from 'antd';
import styles from './Jumbotron.module.scss';
import Container from '../Container/Container';
import classnames from 'classnames';
const { Title } = Typography;
const { Search } = Input;

type Props = {
  title: string;
  subtitle: string;
  backgroundImage: string;
  onSearch?: (input: string) => void;
  searchPlaceholder?: string;
  display?: boolean;
  className?: string;
};

class Jumbotron extends Component<Props, any> {
  static defaultProps = {
    searchPlaceholder: 'Søg på siden',
    display: false
  };

  shouldComponentUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<any>
  ): boolean {
    return (
      this.props.title !== nextProps.title ||
      this.props.subtitle !== nextProps.subtitle
    );
  }

  render() {
    return (
      <div
        className={classnames(styles.jumbotron, this.props.className)}
        style={{
          background: `url(${this.props.backgroundImage}) center no-repeat`
        }}
      >
        <Container>
          {this.props.display ? (
            <Title level={1} className={styles.display}>
              {this.props.title}
            </Title>
          ) : (
            <Title level={1} className={styles.title}>
              {this.props.title}
            </Title>
          )}
          <Title level={3} className={styles.subtitle}>
            {this.props.subtitle}
          </Title>
          {this.props.onSearch && (
            <Search
              className={styles.search}
              placeholder={this.props.searchPlaceholder}
              onSearch={this.props.onSearch}
              size={'large'}
            />
          )}
        </Container>
      </div>
    );
  }
}

export default Jumbotron;
