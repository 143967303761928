import React, { Component } from 'react';
import Header from 'components/Header/Header';
import Footer from 'components/Footer/Footer';
import { Layout } from 'antd';
import './PageSkeleton.scss';
const { Content } = Layout;
type Props = {
  external: boolean;
};

class PageSkeleton extends Component<Props, any> {
  static defaultProps = { external: false };
  render() {
    return (
      <Layout>
        <Header external={this.props.external} />
        <Content>{this.props.children}</Content>
        <Footer external={this.props.external} />
      </Layout>
    );
  }
}

export default PageSkeleton;
