import ApolloClient from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import * as AuthService from 'security/AuthService';
import { customFetch } from './customFetch';
import config from '../config/config';
const { apiUrl } = config;

const httpLink: ApolloLink = createHttpLink({
  uri: `${apiUrl}/graphql`,
  fetch: customFetch
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = AuthService.getToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const cache = new InMemoryCache({
  dataIdFromObject: object => {
    switch (object.__typename) {
      case 'Student':
        return `Student:${(object as any).uniLoginId}`; // use `uniLoginId` as the primary key
      default:
        return defaultDataIdFromObject(object); // fall back to default handling
    }
  },
  cacheRedirects: {
    Query: {
      student: (_, args, { getCacheKey }) =>
        getCacheKey({ __typename: 'Student', id: args.uniLoginId })
    }
  }
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache
});

export { client };
