/* eslint-disable */

export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Date` scalar type represents a year, month and day in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  Date: any;
  /** The `DateTime` scalar type represents a date and time. `DateTime` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTime: any;
  /** The `DateTimeOffset` scalar type represents a date, time and offset from UTC. `DateTimeOffset` expects timestamps to be formatted in accordance with the [ISO-8601](https://en.wikipedia.org/wiki/ISO_8601) standard. */
  DateTimeOffset: any;
  /** The `Seconds` scalar type represents a period of time represented as the total number of seconds. */
  Seconds: any;
  /** The `Milliseconds` scalar type represents a period of time represented as the total number of milliseconds. */
  Milliseconds: any;
  Decimal: any;
};

/** A student's assessment for an objective. */
export type Assessment = {
  attendanceStatus?: Maybe<AttendanceStatus>;
  comment?: Maybe<Scalars['String']>;
  evaluatedAt?: Scalars['Date'];
  id: Scalars['Int'];
  invalidatedAt?: Maybe<Scalars['Date']>;
  invalidationComment?: Maybe<Scalars['String']>;
  isInvalidated: Scalars['Boolean'];
  objectiveId: Scalars['Int'];
  scoreValue?: Maybe<ScoreValue>;
};

export type AssessmentInput = {
  attendanceStatus: AttendanceStatus;
  /** Id of the class the student is in. */
  classId: Scalars['String'];
  /** provide any comment that may be. */
  comment?: Maybe<Scalars['String']>;
  /** Id of the institution the student is enrolled at. */
  institutionId: Scalars['String'];
  /** Id of the objective that was assessed. */
  objectiveId: Scalars['Int'];
  /** The student's uni-login. */
  uniLoginId: Scalars['String'];
  value: ScoreValue;
};

export type AssessmentInvalidationInput = {
  /** Id of the assessment to invalidate. */
  assessmentId: Scalars['Int'];
  /** The student's uni-login. */
  uniLoginId: Scalars['String'];
};

/** Indicates whether the student was present when the assessment was made. */
export enum AttendanceStatus {
  IllegalAbsence = 'ILLEGAL_ABSENCE',
  LegalAbsence = 'LEGAL_ABSENCE',
  Present = 'PRESENT'
}

export type ConfirmResultInput = {
  /** Id of the class the student is in. */
  classId: Scalars['String'];
  /** Id of the institution the student is enrolled at. */
  institutionId: Scalars['String'];
  /** Confirmed proficiency. */
  languageProficiency: LanguageProficiency;
  /** Id of the preschool test. */
  preschoolTestId: Scalars['Int'];
  /** The student's uni-login. */
  uniLoginId: Scalars['String'];
};

/** The student's level of language proficiency. */
export enum LanguageProficiency {
  LesserExtend = 'LESSER_EXTEND',
  NotReady = 'NOT_READY',
  Proficient = 'PROFICIENT',
  Unknown = 'UNKNOWN'
}

export type LanguageTestMutation = {
  /** Confirm a students preschool test result. */
  confirmResult?: Maybe<Student>;
  /** Add a new assessment to a student for an objective. */
  performAssessment?: Maybe<Assessment>;
  /** Invalidate a student's existing assessment. This triggers a proficiency re-calculation. */
  performAssessmentInvalidation?: Maybe<Assessment>;
  /** Reposition the student */
  repositionStudent?: Maybe<Student>;
};

export type LanguageTestMutationConfirmResultArgs = {
  confirmResultInput: ConfirmResultInput;
};

export type LanguageTestMutationPerformAssessmentArgs = {
  assessment: AssessmentInput;
};

export type LanguageTestMutationPerformAssessmentInvalidationArgs = {
  invalidationInput: AssessmentInvalidationInput;
};

export type LanguageTestMutationRepositionStudentArgs = {
  position: Scalars['Int'];
  uniLoginId: Scalars['ID'];
};

export type LanguageTestQuery = {
  material?: Maybe<MaterialType>;
  materials?: Maybe<Array<Maybe<MaterialType>>>;
  objective?: Maybe<Objective>;
  objectives?: Maybe<Array<Maybe<Objective>>>;
  preschoolTest?: Maybe<PreschoolTest>;
  preschoolTests?: Maybe<Array<Maybe<PreschoolTest>>>;
  prioritizedResult?: Maybe<Array<Maybe<PrioritizedAreaGroupResultType>>>;
  school?: Maybe<School>;
  schoolClass?: Maybe<SchoolClass>;
  schools?: Maybe<Array<Maybe<School>>>;
  student?: Maybe<Student>;
};

export type LanguageTestQueryMaterialArgs = {
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

export type LanguageTestQueryObjectiveArgs = {
  id?: Maybe<Scalars['Int']>;
};

export type LanguageTestQueryPreschoolTestArgs = {
  id: Scalars['Int'];
};

export type LanguageTestQueryPrioritizedResultArgs = {
  classId: Scalars['String'];
  institutionId: Scalars['String'];
  preschoolTestId: Scalars['Int'];
};

export type LanguageTestQuerySchoolArgs = {
  id?: Maybe<Scalars['String']>;
};

export type LanguageTestQuerySchoolClassArgs = {
  id?: Maybe<Scalars['String']>;
  institutionId?: Maybe<Scalars['String']>;
};

export type LanguageTestQueryStudentArgs = {
  classId: Scalars['String'];
  institutionId: Scalars['String'];
  uniLoginId: Scalars['String'];
};

/** The linguistic category of an objective. */
export enum LinguisticCategory {
  ConversationAndDialogue = 'CONVERSATION_AND_DIALOGUE',
  GrammaticalSkills = 'GRAMMATICAL_SKILLS',
  NarrativeSkills = 'NARRATIVE_SKILLS',
  PhoneticAttention = 'PHONETIC_ATTENTION',
  Test = 'TEST',
  Vocabulary = 'VOCABULARY',
  WritingAndReading = 'WRITING_AND_READING'
}

export enum MaterialCategory {
  AdditionalLanguageStimulation = 'ADDITIONAL_LANGUAGE_STIMULATION',
  Appendix = 'APPENDIX',
  DialogueTraining = 'DIALOGUE_TRAINING',
  Other = 'OTHER',
  ParentInformation = 'PARENT_INFORMATION',
  RequiredLanguageMaterial = 'REQUIRED_LANGUAGE_MATERIAL'
}

export type MaterialType = {
  description?: Scalars['String'];
  id: Scalars['Int'];
  materialCategory?: Maybe<MaterialCategory>;
  materialType?: Maybe<MaterialTypeEnum>;
  relatedMaterials?: Maybe<Array<Maybe<MaterialType>>>;
  resourcePath?: Scalars['String'];
  title?: Scalars['String'];
};

export enum MaterialTypeEnum {
  Appendix = 'APPENDIX',
  LanguageGame = 'LANGUAGE_GAME',
  Other = 'OTHER',
  ParentActivity = 'PARENT_ACTIVITY',
  TargetedLanguageStimulation = 'TARGETED_LANGUAGE_STIMULATION'
}

export type Objective = {
  description?: Scalars['String'];
  id: Scalars['Int'];
  isMandatory?: Scalars['Boolean'];
  /** The linguistic category of the objective */
  linguisticCategory?: Maybe<LinguisticCategory>;
  /** The linguistic category of the objective */
  materials?: Maybe<Array<Maybe<MaterialType>>>;
  /** The list of available options. */
  options?: Maybe<Array<Maybe<Option>>>;
  orderValue?: Scalars['Int'];
  title?: Scalars['String'];
  topic?: Scalars['String'];
  /** List of tips */
  tips?: Maybe<Array<Maybe<TipType>>>;
};

export type Option = {
  text: Scalars['String'];
  value?: Maybe<ScoreValue>;
};

export type PreschoolTest = {
  id: Scalars['Int'];
  isMandatory?: Scalars['Boolean'];
  /** The objectives belonging to the test. */
  objectives?: Maybe<Array<Maybe<Objective>>>;
  orderValue?: Scalars['Int'];
  /** Test type of the preschool test. */
  testType?: Maybe<TestType>;
  title?: Scalars['String'];
};

export type PreschoolTestResultGroupType = {
  languageProficiency?: Maybe<LanguageProficiency>;
  objectives?: Maybe<Array<Maybe<Objective>>>;
  objectivesWithIllegalAbsence?: Maybe<Array<Maybe<Objective>>>;
  objectivesWithNoAssessment?: Maybe<Array<Maybe<Objective>>>;
  preschoolTest?: Maybe<PreschoolTest>;
  scoreGroups?: Maybe<Array<Maybe<ScoreGroupType>>>;
};

export type PreschoolTestResultType = {
  resultGroups?: Maybe<Array<Maybe<PreschoolTestResultGroupType>>>;
};

export type PrioritizedAreaGroupResultType = {
  objective?: Maybe<Objective>;
  score?: Maybe<Scalars['Int']>;
};

export type ResultConfirmation = {
  languageProficiency?: Maybe<LanguageProficiency>;
  preschoolTest?: Maybe<PreschoolTest>;
};

export type School = {
  id: Scalars['String'];
  name: Scalars['String'];
  schoolClasses?: Maybe<Array<Maybe<SchoolClass>>>;
};

export type SchoolClass = {
  id: Scalars['String'];
  prioritizedAreaResults?: Maybe<Array<Maybe<PrioritizedAreaGroupResultType>>>;
  schoolInstitutionId?: Scalars['String'];
  students?: Maybe<Array<Maybe<Student>>>;
};

export type ScoreGroupType = {
  objectives?: Maybe<Array<Maybe<Objective>>>;
  scoreValue?: Maybe<ScoreValue>;
};

/** Represents the available scores. */
export enum ScoreValue {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
  None = 'NONE'
}

export type Student = {
  /** The student's assessments. */
  assessments?: Maybe<Array<Maybe<Assessment>>>;
  illegalAbsenceForObjectives?: Maybe<Array<Maybe<Objective>>>;
  languageProficiency?: Maybe<LanguageProficiency>;
  name?: Scalars['String'];
  position?: Scalars['Int'];
  preschoolTestResults?: Maybe<PreschoolTestResultType>;
  resultConfirmations?: Maybe<Array<Maybe<ResultConfirmation>>>;
  schoolClassId?: Maybe<Scalars['String']>;
  uniLoginId: Scalars['String'];
};

/** The objective's test type. */
export enum TestType {
  Mandatory = 'MANDATORY',
  Optional = 'OPTIONAL'
}

export type TipType = {
  description: Scalars['String'];
  id: Scalars['Int'];
  title: Scalars['String'];
};
export type ConfirmResultMutationVariables = {
  confirmResultInput: ConfirmResultInput;
};

export type ConfirmResultMutation = { __typename?: 'LanguageTestMutation' } & {
  confirmResult: Maybe<
    { __typename?: 'Student' } & Pick<Student, 'uniLoginId'> & {
        resultConfirmations: Maybe<
          Array<
            Maybe<
              { __typename?: 'ResultConfirmation' } & Pick<
                ResultConfirmation,
                'languageProficiency'
              > & {
                  preschoolTest: Maybe<
                    { __typename?: 'PreschoolTest' } & Pick<PreschoolTest, 'id'>
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type PerformAssessmentMutationVariables = {
  assessment: AssessmentInput;
};

export type PerformAssessmentMutation = {
  __typename?: 'LanguageTestMutation';
} & {
  performAssessment: Maybe<
    { __typename?: 'Assessment' } & Pick<
      Assessment,
      | 'id'
      | 'attendanceStatus'
      | 'comment'
      | 'evaluatedAt'
      | 'objectiveId'
      | 'scoreValue'
      | 'isInvalidated'
    >
  >;
};

export type PerformAssessmentInvalidationMutationVariables = {
  invalidationInput: AssessmentInvalidationInput;
};

export type PerformAssessmentInvalidationMutation = {
  __typename?: 'LanguageTestMutation';
} & {
  performAssessmentInvalidation: Maybe<
    { __typename?: 'Assessment' } & Pick<
      Assessment,
      | 'id'
      | 'attendanceStatus'
      | 'evaluatedAt'
      | 'objectiveId'
      | 'scoreValue'
      | 'isInvalidated'
    >
  >;
};

export type RepositionStudentMutationVariables = {
  position: Scalars['Int'];
  uniLoginId: Scalars['ID'];
};

export type RepositionStudentMutation = {
  __typename?: 'LanguageTestMutation';
} & {
  repositionStudent: Maybe<
    { __typename?: 'Student' } & Pick<Student, 'uniLoginId' | 'position'>
  >;
};

export type AssessmentCardQueryQueryVariables = {
  objectiveId?: Maybe<Scalars['Int']>;
};

export type AssessmentCardQueryQuery = { __typename?: 'LanguageTestQuery' } & {
  objective: Maybe<
    { __typename?: 'Objective' } & Pick<Objective, 'id'> & {
        options: Maybe<
          Array<
            Maybe<{ __typename?: 'Option' } & Pick<Option, 'text' | 'value'>>
          >
        >;
      }
  >;
};

export type BreadcrumbsStudentQueryQueryVariables = {
  uniLoginId: Scalars['String'];
  schoolClassId: Scalars['String'];
  institutionId: Scalars['String'];
};

export type BreadcrumbsStudentQueryQuery = {
  __typename?: 'LanguageTestQuery';
} & {
  student: Maybe<
    { __typename?: 'Student' } & Pick<Student, 'uniLoginId' | 'name'>
  >;
};

export type BreadcrumbsMaterialQueryQueryVariables = {
  materialId?: Maybe<Scalars['Int']>;
};

export type BreadcrumbsMaterialQueryQuery = {
  __typename?: 'LanguageTestQuery';
} & {
  material: Maybe<
    { __typename?: 'MaterialType' } & Pick<MaterialType, 'id' | 'title'>
  >;
};

export type LanguageGameCardQueryQueryVariables = {
  objectiveId?: Maybe<Scalars['Int']>;
};

export type LanguageGameCardQueryQuery = {
  __typename?: 'LanguageTestQuery';
} & {
  objective: Maybe<
    { __typename?: 'Objective' } & Pick<Objective, 'id'> & {
        materials: Maybe<
          Array<
            Maybe<
              { __typename?: 'MaterialType' } & Pick<
                MaterialType,
                'id' | 'title'
              >
            >
          >
        >;
      }
  >;
};

export type LanguageTestTabsQueryVariables = {};

export type LanguageTestTabsQuery = { __typename?: 'LanguageTestQuery' } & {
  preschoolTests: Maybe<
    Array<
      Maybe<
        { __typename?: 'PreschoolTest' } & Pick<
          PreschoolTest,
          'id' | 'title' | 'orderValue'
        >
      >
    >
  >;
};

export type MaterialAppendixListQueryVariables = {
  materialId: Scalars['Int'];
};

export type MaterialAppendixListQuery = { __typename?: 'LanguageTestQuery' } & {
  material: Maybe<
    { __typename?: 'MaterialType' } & Pick<
      MaterialType,
      'id' | 'materialCategory' | 'materialType'
    > & {
        relatedMaterials: Maybe<
          Array<
            Maybe<
              { __typename?: 'MaterialType' } & Pick<
                MaterialType,
                | 'id'
                | 'title'
                | 'resourcePath'
                | 'materialCategory'
                | 'materialType'
              >
            >
          >
        >;
      }
  >;
};

export type MaterialCardQueryQueryVariables = {
  materialId: Scalars['Int'];
};

export type MaterialCardQueryQuery = { __typename?: 'LanguageTestQuery' } & {
  material: Maybe<
    { __typename?: 'MaterialType' } & Pick<
      MaterialType,
      | 'id'
      | 'title'
      | 'description'
      | 'materialCategory'
      | 'resourcePath'
      | 'materialType'
    >
  >;
};

export type MaterialsTabQueryQueryVariables = {
  preschoolTestId: Scalars['Int'];
};

export type MaterialsTabQueryQuery = { __typename?: 'LanguageTestQuery' } & {
  preschoolTest: Maybe<
    { __typename?: 'PreschoolTest' } & Pick<PreschoolTest, 'id' | 'title'> & {
        objectives: Maybe<
          Array<
            Maybe<
              { __typename?: 'Objective' } & Pick<
                Objective,
                'id' | 'isMandatory' | 'title'
              > & {
                  materials: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'MaterialType' } & Pick<
                          MaterialType,
                          | 'id'
                          | 'title'
                          | 'description'
                          | 'materialCategory'
                          | 'materialType'
                          | 'materialCategory'
                        >
                      >
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type ObjectiveCardQueryQueryVariables = {
  objectiveId?: Maybe<Scalars['Int']>;
};

export type ObjectiveCardQueryQuery = { __typename?: 'LanguageTestQuery' } & {
  objective: Maybe<
    { __typename?: 'Objective' } & Pick<
      Objective,
      | 'id'
      | 'linguisticCategory'
      | 'title'
      | 'topic'
      | 'description'
      | 'isMandatory'
    >
  >;
};

export type ObjectiveMaterialsQueryQueryVariables = {
  objectiveId: Scalars['Int'];
};

export type ObjectiveMaterialsQueryQuery = {
  __typename?: 'LanguageTestQuery';
} & {
  objective: Maybe<
    { __typename?: 'Objective' } & Pick<Objective, 'id'> & {
        materials: Maybe<
          Array<
            Maybe<
              { __typename?: 'MaterialType' } & Pick<
                MaterialType,
                'id' | 'materialType'
              > & {
                  relatedMaterials: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'MaterialType' } & Pick<
                          MaterialType,
                          | 'id'
                          | 'materialType'
                          | 'title'
                          | 'description'
                          | 'resourcePath'
                        >
                      >
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type ObjectiveSelectorQueryQueryVariables = {
  preschoolTestId: Scalars['Int'];
};

export type ObjectiveSelectorQueryQuery = {
  __typename?: 'LanguageTestQuery';
} & {
  preschoolTest: Maybe<
    { __typename?: 'PreschoolTest' } & Pick<PreschoolTest, 'id' | 'title'> & {
        objectives: Maybe<
          Array<
            Maybe<
              { __typename?: 'Objective' } & Pick<Objective, 'id' | 'title'>
            >
          >
        >;
      }
  >;
};

export type AllObjectiveSelectorQueryQueryVariables = {};

export type AllObjectiveSelectorQueryQuery = {
  __typename?: 'LanguageTestQuery';
} & {
  objectives: Maybe<
    Array<Maybe<{ __typename?: 'Objective' } & Pick<Objective, 'id' | 'title'>>>
  >;
};

export type ObjectiveSelectorOverviewQueryQueryVariables = {
  schoolClassId?: Maybe<Scalars['String']>;
  institutionId?: Maybe<Scalars['String']>;
};

export type ObjectiveSelectorOverviewQueryQuery = {
  __typename?: 'LanguageTestQuery';
} & {
  preschoolTests: Maybe<
    Array<
      Maybe<
        { __typename?: 'PreschoolTest' } & Pick<
          PreschoolTest,
          'id' | 'orderValue' | 'title'
        > & {
            objectives: Maybe<
              Array<
                Maybe<
                  { __typename?: 'Objective' } & Pick<
                    Objective,
                    'id' | 'title' | 'topic'
                  > & {
                      materials: Maybe<
                        Array<
                          Maybe<
                            { __typename?: 'MaterialType' } & Pick<
                              MaterialType,
                              | 'id'
                              | 'title'
                              | 'materialType'
                              | 'materialCategory'
                              | 'resourcePath'
                            >
                          >
                        >
                      >;
                    }
                >
              >
            >;
          }
      >
    >
  >;
  schoolClass: Maybe<
    { __typename?: 'SchoolClass' } & Pick<SchoolClass, 'id'> & {
        students: Maybe<
          Array<
            Maybe<
              { __typename?: 'Student' } & Pick<Student, 'uniLoginId'> & {
                  preschoolTestResults: Maybe<
                    { __typename?: 'PreschoolTestResultType' } & {
                      resultGroups: Maybe<
                        Array<
                          Maybe<
                            {
                              __typename?: 'PreschoolTestResultGroupType';
                            } & Pick<
                              PreschoolTestResultGroupType,
                              'languageProficiency'
                            > & {
                                preschoolTest: Maybe<
                                  { __typename?: 'PreschoolTest' } & Pick<
                                    PreschoolTest,
                                    'id' | 'title' | 'orderValue'
                                  >
                                >;
                              }
                          >
                        >
                      >;
                    }
                  >;
                  resultConfirmations: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'ResultConfirmation' } & Pick<
                          ResultConfirmation,
                          'languageProficiency'
                        > & {
                            preschoolTest: Maybe<
                              { __typename?: 'PreschoolTest' } & Pick<
                                PreschoolTest,
                                'id' | 'title' | 'orderValue'
                              >
                            >;
                          }
                      >
                    >
                  >;
                  assessments: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'Assessment' } & Pick<
                          Assessment,
                          | 'id'
                          | 'attendanceStatus'
                          | 'objectiveId'
                          | 'isInvalidated'
                        >
                      >
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type OtherMaterialsCardQueryQueryVariables = {};

export type OtherMaterialsCardQueryQuery = {
  __typename?: 'LanguageTestQuery';
} & {
  materials: Maybe<
    Array<
      Maybe<
        { __typename?: 'MaterialType' } & Pick<
          MaterialType,
          'id' | 'title' | 'description' | 'materialType' | 'materialCategory'
        >
      >
    >
  >;
};

export type PreviousAssessmentsQueryQueryVariables = {
  uniLoginId: Scalars['String'];
  schoolClassId: Scalars['String'];
  institutionId: Scalars['String'];
};

export type PreviousAssessmentsQueryQuery = {
  __typename?: 'LanguageTestQuery';
} & {
  student: Maybe<
    { __typename?: 'Student' } & Pick<Student, 'uniLoginId'> & {
        assessments: Maybe<
          Array<
            Maybe<
              { __typename?: 'Assessment' } & Pick<
                Assessment,
                'id' | 'evaluatedAt' | 'isInvalidated' | 'objectiveId'
              >
            >
          >
        >;
      }
  >;
};

export type SchoolCardQueryQueryVariables = {
  schoolInstitutionId?: Maybe<Scalars['String']>;
};

export type SchoolCardQueryQuery = { __typename?: 'LanguageTestQuery' } & {
  school: Maybe<{ __typename?: 'School' } & Pick<School, 'id' | 'name'>>;
};

export type SchoolClassAssessmentsQueryQueryVariables = {
  schoolClassId: Scalars['String'];
  institutionId: Scalars['String'];
};

export type SchoolClassAssessmentsQueryQuery = {
  __typename?: 'LanguageTestQuery';
} & {
  schoolClass: Maybe<
    { __typename?: 'SchoolClass' } & Pick<SchoolClass, 'id'> & {
        students: Maybe<
          Array<
            Maybe<
              { __typename?: 'Student' } & Pick<Student, 'uniLoginId'> & {
                  preschoolTestResults: Maybe<
                    { __typename?: 'PreschoolTestResultType' } & {
                      resultGroups: Maybe<
                        Array<
                          Maybe<
                            {
                              __typename?: 'PreschoolTestResultGroupType';
                            } & Pick<
                              PreschoolTestResultGroupType,
                              'languageProficiency'
                            > & {
                                preschoolTest: Maybe<
                                  { __typename?: 'PreschoolTest' } & Pick<
                                    PreschoolTest,
                                    'id' | 'title' | 'orderValue'
                                  >
                                >;
                              }
                          >
                        >
                      >;
                    }
                  >;
                  resultConfirmations: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'ResultConfirmation' } & Pick<
                          ResultConfirmation,
                          'languageProficiency'
                        > & {
                            preschoolTest: Maybe<
                              { __typename?: 'PreschoolTest' } & Pick<
                                PreschoolTest,
                                'id' | 'title' | 'orderValue'
                              >
                            >;
                          }
                      >
                    >
                  >;
                  assessments: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'Assessment' } & Pick<
                          Assessment,
                          | 'id'
                          | 'attendanceStatus'
                          | 'isInvalidated'
                          | 'objectiveId'
                          | 'scoreValue'
                        >
                      >
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type SchoolClassListQueryQueryVariables = {};

export type SchoolClassListQueryQuery = { __typename?: 'LanguageTestQuery' } & {
  schools: Maybe<
    Array<
      Maybe<
        { __typename?: 'School' } & Pick<School, 'id' | 'name'> & {
            schoolClasses: Maybe<
              Array<
                Maybe<
                  { __typename?: 'SchoolClass' } & Pick<
                    SchoolClass,
                    'id' | 'schoolInstitutionId'
                  > & {
                      students: Maybe<
                        Array<
                          Maybe<
                            { __typename?: 'Student' } & Pick<
                              Student,
                              'uniLoginId'
                            >
                          >
                        >
                      >;
                    }
                >
              >
            >;
          }
      >
    >
  >;
};

export type SchoolClassPrioritizedAreaResultsQueryQueryVariables = {
  schoolClassId: Scalars['String'];
  institutionId: Scalars['String'];
};

export type SchoolClassPrioritizedAreaResultsQueryQuery = {
  __typename?: 'LanguageTestQuery';
} & {
  schoolClass: Maybe<
    { __typename?: 'SchoolClass' } & Pick<SchoolClass, 'id'> & {
        prioritizedAreaResults: Maybe<
          Array<
            Maybe<
              { __typename?: 'PrioritizedAreaGroupResultType' } & Pick<
                PrioritizedAreaGroupResultType,
                'score'
              > & {
                  objective: Maybe<
                    { __typename?: 'Objective' } & Pick<
                      Objective,
                      'title' | 'topic' | 'id' | 'linguisticCategory'
                    >
                  >;
                }
            >
          >
        >;
        students: Maybe<
          Array<
            Maybe<
              { __typename?: 'Student' } & Pick<Student, 'uniLoginId'> & {
                  preschoolTestResults: Maybe<
                    { __typename?: 'PreschoolTestResultType' } & {
                      resultGroups: Maybe<
                        Array<
                          Maybe<
                            {
                              __typename?: 'PreschoolTestResultGroupType';
                            } & Pick<
                              PreschoolTestResultGroupType,
                              'languageProficiency'
                            > & {
                                preschoolTest: Maybe<
                                  { __typename?: 'PreschoolTest' } & Pick<
                                    PreschoolTest,
                                    'id' | 'title' | 'orderValue'
                                  >
                                >;
                              }
                          >
                        >
                      >;
                    }
                  >;
                  resultConfirmations: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'ResultConfirmation' } & Pick<
                          ResultConfirmation,
                          'languageProficiency'
                        > & {
                            preschoolTest: Maybe<
                              { __typename?: 'PreschoolTest' } & Pick<
                                PreschoolTest,
                                'id' | 'title' | 'orderValue'
                              >
                            >;
                          }
                      >
                    >
                  >;
                  assessments: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'Assessment' } & Pick<
                          Assessment,
                          | 'id'
                          | 'attendanceStatus'
                          | 'isInvalidated'
                          | 'objectiveId'
                        >
                      >
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type SchoolClassResultTabQueryQueryVariables = {
  preschoolTestId: Scalars['Int'];
  schoolClassId: Scalars['String'];
  institutionId: Scalars['String'];
};

export type SchoolClassResultTabQueryQuery = {
  __typename?: 'LanguageTestQuery';
} & {
  objectives: Maybe<
    Array<
      Maybe<
        { __typename?: 'Objective' } & Pick<Objective, 'id'> & {
            materials: Maybe<
              Array<
                Maybe<
                  { __typename?: 'MaterialType' } & Pick<
                    MaterialType,
                    'id' | 'materialType' | 'resourcePath'
                  >
                >
              >
            >;
          }
      >
    >
  >;
  preschoolTest: Maybe<
    { __typename?: 'PreschoolTest' } & Pick<PreschoolTest, 'id'> & {
        objectives: Maybe<
          Array<Maybe<{ __typename?: 'Objective' } & Pick<Objective, 'id'>>>
        >;
      }
  >;
  preschoolTests: Maybe<
    Array<
      Maybe<
        { __typename?: 'PreschoolTest' } & Pick<
          PreschoolTest,
          'id' | 'orderValue'
        > & {
            objectives: Maybe<
              Array<Maybe<{ __typename?: 'Objective' } & Pick<Objective, 'id'>>>
            >;
          }
      >
    >
  >;
  prioritizedResult: Maybe<
    Array<
      Maybe<
        { __typename?: 'PrioritizedAreaGroupResultType' } & Pick<
          PrioritizedAreaGroupResultType,
          'score'
        > & {
            objective: Maybe<
              { __typename?: 'Objective' } & Pick<
                Objective,
                'title' | 'topic' | 'id' | 'linguisticCategory'
              >
            >;
          }
      >
    >
  >;
  schoolClass: Maybe<
    { __typename?: 'SchoolClass' } & Pick<SchoolClass, 'id'> & {
        students: Maybe<
          Array<
            Maybe<
              { __typename?: 'Student' } & Pick<Student, 'uniLoginId'> & {
                  preschoolTestResults: Maybe<
                    { __typename?: 'PreschoolTestResultType' } & {
                      resultGroups: Maybe<
                        Array<
                          Maybe<
                            {
                              __typename?: 'PreschoolTestResultGroupType';
                            } & Pick<
                              PreschoolTestResultGroupType,
                              'languageProficiency'
                            > & {
                                preschoolTest: Maybe<
                                  { __typename?: 'PreschoolTest' } & Pick<
                                    PreschoolTest,
                                    'id' | 'title' | 'orderValue'
                                  >
                                >;
                              }
                          >
                        >
                      >;
                    }
                  >;
                  resultConfirmations: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'ResultConfirmation' } & Pick<
                          ResultConfirmation,
                          'languageProficiency'
                        > & {
                            preschoolTest: Maybe<
                              { __typename?: 'PreschoolTest' } & Pick<
                                PreschoolTest,
                                'id' | 'title' | 'orderValue'
                              >
                            >;
                          }
                      >
                    >
                  >;
                  assessments: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'Assessment' } & Pick<
                          Assessment,
                          | 'id'
                          | 'attendanceStatus'
                          | 'isInvalidated'
                          | 'objectiveId'
                        >
                      >
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type ShowLanguageGameButtonQueryQueryVariables = {
  objectiveId?: Maybe<Scalars['Int']>;
};

export type ShowLanguageGameButtonQueryQuery = {
  __typename?: 'LanguageTestQuery';
} & {
  objective: Maybe<
    { __typename?: 'Objective' } & Pick<Objective, 'id'> & {
        materials: Maybe<
          Array<
            Maybe<{ __typename?: 'MaterialType' } & Pick<MaterialType, 'id'>>
          >
        >;
      }
  >;
};

export type StudentListQueryQueryVariables = {
  schoolClassId?: Maybe<Scalars['String']>;
  institutionId?: Maybe<Scalars['String']>;
};

export type StudentListQueryQuery = { __typename?: 'LanguageTestQuery' } & {
  schoolClass: Maybe<
    { __typename?: 'SchoolClass' } & Pick<SchoolClass, 'id'> & {
        students: Maybe<
          Array<
            Maybe<
              { __typename?: 'Student' } & Pick<
                Student,
                'uniLoginId' | 'name' | 'position'
              > & {
                  preschoolTestResults: Maybe<
                    { __typename?: 'PreschoolTestResultType' } & {
                      resultGroups: Maybe<
                        Array<
                          Maybe<
                            {
                              __typename?: 'PreschoolTestResultGroupType';
                            } & Pick<
                              PreschoolTestResultGroupType,
                              'languageProficiency'
                            > & {
                                preschoolTest: Maybe<
                                  { __typename?: 'PreschoolTest' } & Pick<
                                    PreschoolTest,
                                    'id' | 'title' | 'orderValue'
                                  >
                                >;
                              }
                          >
                        >
                      >;
                    }
                  >;
                  resultConfirmations: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'ResultConfirmation' } & Pick<
                          ResultConfirmation,
                          'languageProficiency'
                        > & {
                            preschoolTest: Maybe<
                              { __typename?: 'PreschoolTest' } & Pick<
                                PreschoolTest,
                                'id' | 'title' | 'orderValue'
                              >
                            >;
                          }
                      >
                    >
                  >;
                  assessments: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'Assessment' } & Pick<
                          Assessment,
                          | 'id'
                          | 'attendanceStatus'
                          | 'objectiveId'
                          | 'scoreValue'
                          | 'isInvalidated'
                        >
                      >
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
  preschoolTests: Maybe<
    Array<
      Maybe<
        { __typename?: 'PreschoolTest' } & Pick<
          PreschoolTest,
          'id' | 'orderValue'
        >
      >
    >
  >;
};

export type StudentListItemQueryQueryVariables = {
  uniLoginId: Scalars['String'];
  schoolClassId: Scalars['String'];
  institutionId: Scalars['String'];
};

export type StudentListItemQueryQuery = { __typename?: 'LanguageTestQuery' } & {
  student: Maybe<
    { __typename?: 'Student' } & Pick<Student, 'name' | 'uniLoginId'> & {
        assessments: Maybe<
          Array<
            Maybe<
              { __typename?: 'Assessment' } & Pick<
                Assessment,
                'id' | 'attendanceStatus' | 'objectiveId' | 'scoreValue'
              >
            >
          >
        >;
      }
  >;
};

export type StudentResultGroupQueryQueryVariables = {
  uniLoginId: Scalars['String'];
  schoolClassId: Scalars['String'];
  institutionId: Scalars['String'];
};

export type StudentResultGroupQueryQuery = {
  __typename?: 'LanguageTestQuery';
} & {
  student: Maybe<
    { __typename?: 'Student' } & Pick<
      Student,
      'uniLoginId' | 'name' | 'position'
    > & {
        preschoolTestResults: Maybe<
          { __typename?: 'PreschoolTestResultType' } & {
            resultGroups: Maybe<
              Array<
                Maybe<
                  { __typename?: 'PreschoolTestResultGroupType' } & Pick<
                    PreschoolTestResultGroupType,
                    'languageProficiency'
                  > & {
                      preschoolTest: Maybe<
                        { __typename?: 'PreschoolTest' } & Pick<
                          PreschoolTest,
                          'id' | 'title' | 'orderValue'
                        >
                      >;
                    }
                >
              >
            >;
          }
        >;
        resultConfirmations: Maybe<
          Array<
            Maybe<
              { __typename?: 'ResultConfirmation' } & Pick<
                ResultConfirmation,
                'languageProficiency'
              > & {
                  preschoolTest: Maybe<
                    { __typename?: 'PreschoolTest' } & Pick<
                      PreschoolTest,
                      'id' | 'title' | 'orderValue'
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type StudentResultItemQueryQueryVariables = {
  uniLoginId: Scalars['String'];
  schoolClassId: Scalars['String'];
  institutionId: Scalars['String'];
};

export type StudentResultItemQueryQuery = {
  __typename?: 'LanguageTestQuery';
} & {
  student: Maybe<
    { __typename?: 'Student' } & Pick<
      Student,
      'name' | 'uniLoginId' | 'languageProficiency'
    > & {
        preschoolTestResults: Maybe<
          { __typename?: 'PreschoolTestResultType' } & {
            resultGroups: Maybe<
              Array<
                Maybe<
                  { __typename?: 'PreschoolTestResultGroupType' } & Pick<
                    PreschoolTestResultGroupType,
                    'languageProficiency'
                  > & {
                      preschoolTest: Maybe<
                        { __typename?: 'PreschoolTest' } & Pick<
                          PreschoolTest,
                          'id' | 'title' | 'orderValue'
                        >
                      >;
                    }
                >
              >
            >;
          }
        >;
        resultConfirmations: Maybe<
          Array<
            Maybe<
              { __typename?: 'ResultConfirmation' } & Pick<
                ResultConfirmation,
                'languageProficiency'
              > & {
                  preschoolTest: Maybe<
                    { __typename?: 'PreschoolTest' } & Pick<
                      PreschoolTest,
                      'id' | 'title' | 'orderValue'
                    >
                  >;
                }
            >
          >
        >;
        assessments: Maybe<
          Array<
            Maybe<
              { __typename?: 'Assessment' } & Pick<
                Assessment,
                'id' | 'attendanceStatus' | 'objectiveId' | 'scoreValue'
              >
            >
          >
        >;
      }
  >;
};

export type StudentResultListQueryQueryVariables = {
  schoolClassId?: Maybe<Scalars['String']>;
  institutionId?: Maybe<Scalars['String']>;
};

export type StudentResultListQueryQuery = {
  __typename?: 'LanguageTestQuery';
} & {
  schoolClass: Maybe<
    { __typename?: 'SchoolClass' } & Pick<SchoolClass, 'id'> & {
        students: Maybe<
          Array<
            Maybe<
              { __typename?: 'Student' } & Pick<
                Student,
                'uniLoginId' | 'name' | 'position' | 'languageProficiency'
              > & {
                  preschoolTestResults: Maybe<
                    { __typename?: 'PreschoolTestResultType' } & {
                      resultGroups: Maybe<
                        Array<
                          Maybe<
                            {
                              __typename?: 'PreschoolTestResultGroupType';
                            } & Pick<
                              PreschoolTestResultGroupType,
                              'languageProficiency'
                            > & {
                                preschoolTest: Maybe<
                                  { __typename?: 'PreschoolTest' } & Pick<
                                    PreschoolTest,
                                    'id' | 'title' | 'orderValue'
                                  >
                                >;
                              }
                          >
                        >
                      >;
                    }
                  >;
                  resultConfirmations: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'ResultConfirmation' } & Pick<
                          ResultConfirmation,
                          'languageProficiency'
                        > & {
                            preschoolTest: Maybe<
                              { __typename?: 'PreschoolTest' } & Pick<
                                PreschoolTest,
                                'id' | 'title' | 'orderValue'
                              >
                            >;
                          }
                      >
                    >
                  >;
                  assessments: Maybe<
                    Array<
                      Maybe<
                        { __typename?: 'Assessment' } & Pick<
                          Assessment,
                          | 'id'
                          | 'attendanceStatus'
                          | 'objectiveId'
                          | 'scoreValue'
                        >
                      >
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
  preschoolTests: Maybe<
    Array<
      Maybe<
        { __typename?: 'PreschoolTest' } & Pick<
          PreschoolTest,
          'id' | 'orderValue'
        >
      >
    >
  >;
};

export type StudentResultTabQueryQueryVariables = {
  uniLoginId: Scalars['String'];
  schoolClassId: Scalars['String'];
  institutionId: Scalars['String'];
};

export type StudentResultTabQueryQuery = {
  __typename?: 'LanguageTestQuery';
} & {
  objectives: Maybe<
    Array<
      Maybe<
        { __typename?: 'Objective' } & Pick<Objective, 'id'> & {
            materials: Maybe<
              Array<
                Maybe<
                  { __typename?: 'MaterialType' } & Pick<
                    MaterialType,
                    'id' | 'materialType' | 'materialCategory'
                  >
                >
              >
            >;
          }
      >
    >
  >;
  preschoolTests: Maybe<
    Array<
      Maybe<
        { __typename?: 'PreschoolTest' } & Pick<
          PreschoolTest,
          'id' | 'title' | 'orderValue'
        > & {
            objectives: Maybe<
              Array<Maybe<{ __typename?: 'Objective' } & Pick<Objective, 'id'>>>
            >;
          }
      >
    >
  >;
  student: Maybe<
    { __typename?: 'Student' } & Pick<Student, 'uniLoginId'> & {
        preschoolTestResults: Maybe<
          { __typename?: 'PreschoolTestResultType' } & {
            resultGroups: Maybe<
              Array<
                Maybe<
                  { __typename?: 'PreschoolTestResultGroupType' } & Pick<
                    PreschoolTestResultGroupType,
                    'languageProficiency'
                  > & {
                      scoreGroups: Maybe<
                        Array<
                          Maybe<
                            { __typename?: 'ScoreGroupType' } & Pick<
                              ScoreGroupType,
                              'scoreValue'
                            > & {
                                objectives: Maybe<
                                  Array<
                                    Maybe<
                                      { __typename?: 'Objective' } & Pick<
                                        Objective,
                                        'id' | 'title' | 'topic'
                                      >
                                    >
                                  >
                                >;
                              }
                          >
                        >
                      >;
                      preschoolTest: Maybe<
                        { __typename?: 'PreschoolTest' } & Pick<
                          PreschoolTest,
                          'id' | 'title'
                        >
                      >;
                      objectivesWithIllegalAbsence: Maybe<
                        Array<
                          Maybe<
                            { __typename?: 'Objective' } & Pick<
                              Objective,
                              'id' | 'title' | 'topic'
                            >
                          >
                        >
                      >;
                      objectivesWithNoAssessment: Maybe<
                        Array<
                          Maybe<
                            { __typename?: 'Objective' } & Pick<
                              Objective,
                              'id' | 'title' | 'topic'
                            >
                          >
                        >
                      >;
                    }
                >
              >
            >;
          }
        >;
        resultConfirmations: Maybe<
          Array<
            Maybe<
              { __typename?: 'ResultConfirmation' } & Pick<
                ResultConfirmation,
                'languageProficiency'
              > & {
                  preschoolTest: Maybe<
                    { __typename?: 'PreschoolTest' } & Pick<
                      PreschoolTest,
                      'id' | 'title' | 'orderValue'
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type StudentResultTipsAndTricksQueryQueryVariables = {
  uniLoginId: Scalars['String'];
  schoolClassId: Scalars['String'];
  institutionId: Scalars['String'];
};

export type StudentResultTipsAndTricksQueryQuery = {
  __typename?: 'LanguageTestQuery';
} & {
  student: Maybe<
    { __typename?: 'Student' } & Pick<Student, 'uniLoginId'> & {
        preschoolTestResults: Maybe<
          { __typename?: 'PreschoolTestResultType' } & {
            resultGroups: Maybe<
              Array<
                Maybe<
                  { __typename?: 'PreschoolTestResultGroupType' } & {
                    preschoolTest: Maybe<
                      { __typename?: 'PreschoolTest' } & Pick<
                        PreschoolTest,
                        'id' | 'title'
                      >
                    >;
                    scoreGroups: Maybe<
                      Array<
                        Maybe<
                          { __typename?: 'ScoreGroupType' } & Pick<
                            ScoreGroupType,
                            'scoreValue'
                          > & {
                              objectives: Maybe<
                                Array<
                                  Maybe<
                                    { __typename?: 'Objective' } & Pick<
                                      Objective,
                                      'id' | 'title' | 'topic'
                                    >
                                  >
                                >
                              >;
                            }
                        >
                      >
                    >;
                    objectivesWithIllegalAbsence: Maybe<
                      Array<
                        Maybe<
                          { __typename?: 'Objective' } & Pick<
                            Objective,
                            'id' | 'title' | 'topic'
                          >
                        >
                      >
                    >;
                  }
                >
              >
            >;
          }
        >;
        resultConfirmations: Maybe<
          Array<
            Maybe<
              { __typename?: 'ResultConfirmation' } & Pick<
                ResultConfirmation,
                'languageProficiency'
              > & {
                  preschoolTest: Maybe<
                    { __typename?: 'PreschoolTest' } & Pick<
                      PreschoolTest,
                      'id' | 'orderValue'
                    >
                  >;
                }
            >
          >
        >;
      }
  >;
};

export type TipsAndTricksQueryQueryVariables = {
  objectiveId: Scalars['Int'];
};

export type TipsAndTricksQueryQuery = { __typename?: 'LanguageTestQuery' } & {
  objective: Maybe<
    { __typename?: 'Objective' } & Pick<Objective, 'id'> & {
        tips: Maybe<
          Array<
            Maybe<
              { __typename?: 'TipType' } & Pick<
                TipType,
                'id' | 'description' | 'title'
              >
            >
          >
        >;
      }
  >;
};

import gql from 'graphql-tag';
import * as React from 'react';
import * as ReactApollo from 'react-apollo';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export const ConfirmResultDocument = gql`
  mutation ConfirmResult($confirmResultInput: confirmResultInput!) {
    confirmResult(confirmResultInput: $confirmResultInput) {
      uniLoginId
      resultConfirmations {
        languageProficiency
        preschoolTest {
          id
        }
      }
    }
  }
`;
export type ConfirmResultMutationFn = ReactApollo.MutationFn<
  ConfirmResultMutation,
  ConfirmResultMutationVariables
>;

export const ConfirmResultComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<
        ConfirmResultMutation,
        ConfirmResultMutationVariables
      >,
      'mutation'
    >,
    'variables'
  > & { variables?: ConfirmResultMutationVariables }
) => (
  <ReactApollo.Mutation<ConfirmResultMutation, ConfirmResultMutationVariables>
    mutation={ConfirmResultDocument}
    {...props}
  />
);

export type ConfirmResultProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<ConfirmResultMutation, ConfirmResultMutationVariables>
> &
  TChildProps;
export function withConfirmResult<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ConfirmResultMutation,
    ConfirmResultMutationVariables,
    ConfirmResultProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    ConfirmResultMutation,
    ConfirmResultMutationVariables,
    ConfirmResultProps<TChildProps>
  >(ConfirmResultDocument, {
    alias: 'withConfirmResult',
    ...operationOptions
  });
}
export const PerformAssessmentDocument = gql`
  mutation PerformAssessment($assessment: assessmentInput!) {
    performAssessment(assessment: $assessment) {
      id
      attendanceStatus
      comment
      evaluatedAt
      objectiveId
      scoreValue
      isInvalidated
    }
  }
`;
export type PerformAssessmentMutationFn = ReactApollo.MutationFn<
  PerformAssessmentMutation,
  PerformAssessmentMutationVariables
>;

export const PerformAssessmentComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<
        PerformAssessmentMutation,
        PerformAssessmentMutationVariables
      >,
      'mutation'
    >,
    'variables'
  > & { variables?: PerformAssessmentMutationVariables }
) => (
  <ReactApollo.Mutation<
    PerformAssessmentMutation,
    PerformAssessmentMutationVariables
  >
    mutation={PerformAssessmentDocument}
    {...props}
  />
);

export type PerformAssessmentProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    PerformAssessmentMutation,
    PerformAssessmentMutationVariables
  >
> &
  TChildProps;
export function withPerformAssessment<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    PerformAssessmentMutation,
    PerformAssessmentMutationVariables,
    PerformAssessmentProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    PerformAssessmentMutation,
    PerformAssessmentMutationVariables,
    PerformAssessmentProps<TChildProps>
  >(PerformAssessmentDocument, {
    alias: 'withPerformAssessment',
    ...operationOptions
  });
}
export const PerformAssessmentInvalidationDocument = gql`
  mutation PerformAssessmentInvalidation(
    $invalidationInput: assessmentInvalidationInput!
  ) {
    performAssessmentInvalidation(invalidationInput: $invalidationInput) {
      id
      attendanceStatus
      evaluatedAt
      objectiveId
      scoreValue
      isInvalidated
    }
  }
`;
export type PerformAssessmentInvalidationMutationFn = ReactApollo.MutationFn<
  PerformAssessmentInvalidationMutation,
  PerformAssessmentInvalidationMutationVariables
>;

export const PerformAssessmentInvalidationComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<
        PerformAssessmentInvalidationMutation,
        PerformAssessmentInvalidationMutationVariables
      >,
      'mutation'
    >,
    'variables'
  > & { variables?: PerformAssessmentInvalidationMutationVariables }
) => (
  <ReactApollo.Mutation<
    PerformAssessmentInvalidationMutation,
    PerformAssessmentInvalidationMutationVariables
  >
    mutation={PerformAssessmentInvalidationDocument}
    {...props}
  />
);

export type PerformAssessmentInvalidationProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    PerformAssessmentInvalidationMutation,
    PerformAssessmentInvalidationMutationVariables
  >
> &
  TChildProps;
export function withPerformAssessmentInvalidation<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    PerformAssessmentInvalidationMutation,
    PerformAssessmentInvalidationMutationVariables,
    PerformAssessmentInvalidationProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    PerformAssessmentInvalidationMutation,
    PerformAssessmentInvalidationMutationVariables,
    PerformAssessmentInvalidationProps<TChildProps>
  >(PerformAssessmentInvalidationDocument, {
    alias: 'withPerformAssessmentInvalidation',
    ...operationOptions
  });
}
export const RepositionStudentDocument = gql`
  mutation RepositionStudent($position: Int!, $uniLoginId: ID!) {
    repositionStudent(position: $position, uniLoginId: $uniLoginId) {
      uniLoginId
      position
    }
  }
`;
export type RepositionStudentMutationFn = ReactApollo.MutationFn<
  RepositionStudentMutation,
  RepositionStudentMutationVariables
>;

export const RepositionStudentComponent = (
  props: Omit<
    Omit<
      ReactApollo.MutationProps<
        RepositionStudentMutation,
        RepositionStudentMutationVariables
      >,
      'mutation'
    >,
    'variables'
  > & { variables?: RepositionStudentMutationVariables }
) => (
  <ReactApollo.Mutation<
    RepositionStudentMutation,
    RepositionStudentMutationVariables
  >
    mutation={RepositionStudentDocument}
    {...props}
  />
);

export type RepositionStudentProps<TChildProps = {}> = Partial<
  ReactApollo.MutateProps<
    RepositionStudentMutation,
    RepositionStudentMutationVariables
  >
> &
  TChildProps;
export function withRepositionStudent<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    RepositionStudentMutation,
    RepositionStudentMutationVariables,
    RepositionStudentProps<TChildProps>
  >
) {
  return ReactApollo.withMutation<
    TProps,
    RepositionStudentMutation,
    RepositionStudentMutationVariables,
    RepositionStudentProps<TChildProps>
  >(RepositionStudentDocument, {
    alias: 'withRepositionStudent',
    ...operationOptions
  });
}
export const AssessmentCardQueryDocument = gql`
  query AssessmentCardQuery($objectiveId: Int) {
    objective(id: $objectiveId) {
      id
      options {
        text
        value
      }
    }
  }
`;

export const AssessmentCardQueryComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        AssessmentCardQueryQuery,
        AssessmentCardQueryQueryVariables
      >,
      'query'
    >,
    'variables'
  > & { variables?: AssessmentCardQueryQueryVariables }
) => (
  <ReactApollo.Query<
    AssessmentCardQueryQuery,
    AssessmentCardQueryQueryVariables
  >
    query={AssessmentCardQueryDocument}
    {...props}
  />
);

export type AssessmentCardQueryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    AssessmentCardQueryQuery,
    AssessmentCardQueryQueryVariables
  >
> &
  TChildProps;
export function withAssessmentCardQuery<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    AssessmentCardQueryQuery,
    AssessmentCardQueryQueryVariables,
    AssessmentCardQueryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    AssessmentCardQueryQuery,
    AssessmentCardQueryQueryVariables,
    AssessmentCardQueryProps<TChildProps>
  >(AssessmentCardQueryDocument, {
    alias: 'withAssessmentCardQuery',
    ...operationOptions
  });
}
export const BreadcrumbsStudentQueryDocument = gql`
  query BreadcrumbsStudentQuery(
    $uniLoginId: String!
    $schoolClassId: String!
    $institutionId: String!
  ) {
    student(
      uniLoginId: $uniLoginId
      classId: $schoolClassId
      institutionId: $institutionId
    ) {
      uniLoginId
      name
    }
  }
`;

export const BreadcrumbsStudentQueryComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        BreadcrumbsStudentQueryQuery,
        BreadcrumbsStudentQueryQueryVariables
      >,
      'query'
    >,
    'variables'
  > & { variables: BreadcrumbsStudentQueryQueryVariables }
) => (
  <ReactApollo.Query<
    BreadcrumbsStudentQueryQuery,
    BreadcrumbsStudentQueryQueryVariables
  >
    query={BreadcrumbsStudentQueryDocument}
    {...props}
  />
);

export type BreadcrumbsStudentQueryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    BreadcrumbsStudentQueryQuery,
    BreadcrumbsStudentQueryQueryVariables
  >
> &
  TChildProps;
export function withBreadcrumbsStudentQuery<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    BreadcrumbsStudentQueryQuery,
    BreadcrumbsStudentQueryQueryVariables,
    BreadcrumbsStudentQueryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    BreadcrumbsStudentQueryQuery,
    BreadcrumbsStudentQueryQueryVariables,
    BreadcrumbsStudentQueryProps<TChildProps>
  >(BreadcrumbsStudentQueryDocument, {
    alias: 'withBreadcrumbsStudentQuery',
    ...operationOptions
  });
}
export const BreadcrumbsMaterialQueryDocument = gql`
  query BreadcrumbsMaterialQuery($materialId: Int) {
    material(id: $materialId) {
      id
      title
    }
  }
`;

export const BreadcrumbsMaterialQueryComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        BreadcrumbsMaterialQueryQuery,
        BreadcrumbsMaterialQueryQueryVariables
      >,
      'query'
    >,
    'variables'
  > & { variables?: BreadcrumbsMaterialQueryQueryVariables }
) => (
  <ReactApollo.Query<
    BreadcrumbsMaterialQueryQuery,
    BreadcrumbsMaterialQueryQueryVariables
  >
    query={BreadcrumbsMaterialQueryDocument}
    {...props}
  />
);

export type BreadcrumbsMaterialQueryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    BreadcrumbsMaterialQueryQuery,
    BreadcrumbsMaterialQueryQueryVariables
  >
> &
  TChildProps;
export function withBreadcrumbsMaterialQuery<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    BreadcrumbsMaterialQueryQuery,
    BreadcrumbsMaterialQueryQueryVariables,
    BreadcrumbsMaterialQueryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    BreadcrumbsMaterialQueryQuery,
    BreadcrumbsMaterialQueryQueryVariables,
    BreadcrumbsMaterialQueryProps<TChildProps>
  >(BreadcrumbsMaterialQueryDocument, {
    alias: 'withBreadcrumbsMaterialQuery',
    ...operationOptions
  });
}
export const LanguageGameCardQueryDocument = gql`
  query LanguageGameCardQuery($objectiveId: Int) {
    objective(id: $objectiveId) {
      id
      materials {
        id
        title
      }
    }
  }
`;

export const LanguageGameCardQueryComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        LanguageGameCardQueryQuery,
        LanguageGameCardQueryQueryVariables
      >,
      'query'
    >,
    'variables'
  > & { variables?: LanguageGameCardQueryQueryVariables }
) => (
  <ReactApollo.Query<
    LanguageGameCardQueryQuery,
    LanguageGameCardQueryQueryVariables
  >
    query={LanguageGameCardQueryDocument}
    {...props}
  />
);

export type LanguageGameCardQueryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    LanguageGameCardQueryQuery,
    LanguageGameCardQueryQueryVariables
  >
> &
  TChildProps;
export function withLanguageGameCardQuery<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    LanguageGameCardQueryQuery,
    LanguageGameCardQueryQueryVariables,
    LanguageGameCardQueryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    LanguageGameCardQueryQuery,
    LanguageGameCardQueryQueryVariables,
    LanguageGameCardQueryProps<TChildProps>
  >(LanguageGameCardQueryDocument, {
    alias: 'withLanguageGameCardQuery',
    ...operationOptions
  });
}
export const LanguageTestTabsDocument = gql`
  query LanguageTestTabs {
    preschoolTests {
      id
      title
      orderValue
    }
  }
`;

export const LanguageTestTabsComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        LanguageTestTabsQuery,
        LanguageTestTabsQueryVariables
      >,
      'query'
    >,
    'variables'
  > & { variables?: LanguageTestTabsQueryVariables }
) => (
  <ReactApollo.Query<LanguageTestTabsQuery, LanguageTestTabsQueryVariables>
    query={LanguageTestTabsDocument}
    {...props}
  />
);

export type LanguageTestTabsProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<LanguageTestTabsQuery, LanguageTestTabsQueryVariables>
> &
  TChildProps;
export function withLanguageTestTabs<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    LanguageTestTabsQuery,
    LanguageTestTabsQueryVariables,
    LanguageTestTabsProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    LanguageTestTabsQuery,
    LanguageTestTabsQueryVariables,
    LanguageTestTabsProps<TChildProps>
  >(LanguageTestTabsDocument, {
    alias: 'withLanguageTestTabs',
    ...operationOptions
  });
}
export const MaterialAppendixListDocument = gql`
  query MaterialAppendixList($materialId: Int!) {
    material(id: $materialId) {
      id
      relatedMaterials {
        id
        title
        resourcePath
        materialCategory
        materialType
      }
      materialCategory
      materialType
    }
  }
`;

export const MaterialAppendixListComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        MaterialAppendixListQuery,
        MaterialAppendixListQueryVariables
      >,
      'query'
    >,
    'variables'
  > & { variables: MaterialAppendixListQueryVariables }
) => (
  <ReactApollo.Query<
    MaterialAppendixListQuery,
    MaterialAppendixListQueryVariables
  >
    query={MaterialAppendixListDocument}
    {...props}
  />
);

export type MaterialAppendixListProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    MaterialAppendixListQuery,
    MaterialAppendixListQueryVariables
  >
> &
  TChildProps;
export function withMaterialAppendixList<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    MaterialAppendixListQuery,
    MaterialAppendixListQueryVariables,
    MaterialAppendixListProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    MaterialAppendixListQuery,
    MaterialAppendixListQueryVariables,
    MaterialAppendixListProps<TChildProps>
  >(MaterialAppendixListDocument, {
    alias: 'withMaterialAppendixList',
    ...operationOptions
  });
}
export const MaterialCardQueryDocument = gql`
  query MaterialCardQuery($materialId: Int!) {
    material(id: $materialId) {
      id
      title
      description
      materialCategory
      resourcePath
      materialType
    }
  }
`;

export const MaterialCardQueryComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        MaterialCardQueryQuery,
        MaterialCardQueryQueryVariables
      >,
      'query'
    >,
    'variables'
  > & { variables: MaterialCardQueryQueryVariables }
) => (
  <ReactApollo.Query<MaterialCardQueryQuery, MaterialCardQueryQueryVariables>
    query={MaterialCardQueryDocument}
    {...props}
  />
);

export type MaterialCardQueryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<MaterialCardQueryQuery, MaterialCardQueryQueryVariables>
> &
  TChildProps;
export function withMaterialCardQuery<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    MaterialCardQueryQuery,
    MaterialCardQueryQueryVariables,
    MaterialCardQueryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    MaterialCardQueryQuery,
    MaterialCardQueryQueryVariables,
    MaterialCardQueryProps<TChildProps>
  >(MaterialCardQueryDocument, {
    alias: 'withMaterialCardQuery',
    ...operationOptions
  });
}
export const MaterialsTabQueryDocument = gql`
  query MaterialsTabQuery($preschoolTestId: Int!) {
    preschoolTest(id: $preschoolTestId) {
      id
      title
      objectives {
        id
        isMandatory
        title
        materials {
          id
          title
          description
          materialCategory
          materialType
          materialCategory
        }
      }
    }
  }
`;

export const MaterialsTabQueryComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        MaterialsTabQueryQuery,
        MaterialsTabQueryQueryVariables
      >,
      'query'
    >,
    'variables'
  > & { variables: MaterialsTabQueryQueryVariables }
) => (
  <ReactApollo.Query<MaterialsTabQueryQuery, MaterialsTabQueryQueryVariables>
    query={MaterialsTabQueryDocument}
    {...props}
  />
);

export type MaterialsTabQueryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<MaterialsTabQueryQuery, MaterialsTabQueryQueryVariables>
> &
  TChildProps;
export function withMaterialsTabQuery<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    MaterialsTabQueryQuery,
    MaterialsTabQueryQueryVariables,
    MaterialsTabQueryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    MaterialsTabQueryQuery,
    MaterialsTabQueryQueryVariables,
    MaterialsTabQueryProps<TChildProps>
  >(MaterialsTabQueryDocument, {
    alias: 'withMaterialsTabQuery',
    ...operationOptions
  });
}
export const ObjectiveCardQueryDocument = gql`
  query ObjectiveCardQuery($objectiveId: Int) {
    objective(id: $objectiveId) {
      id
      linguisticCategory
      title
      topic
      description
      isMandatory
    }
  }
`;

export const ObjectiveCardQueryComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        ObjectiveCardQueryQuery,
        ObjectiveCardQueryQueryVariables
      >,
      'query'
    >,
    'variables'
  > & { variables?: ObjectiveCardQueryQueryVariables }
) => (
  <ReactApollo.Query<ObjectiveCardQueryQuery, ObjectiveCardQueryQueryVariables>
    query={ObjectiveCardQueryDocument}
    {...props}
  />
);

export type ObjectiveCardQueryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    ObjectiveCardQueryQuery,
    ObjectiveCardQueryQueryVariables
  >
> &
  TChildProps;
export function withObjectiveCardQuery<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ObjectiveCardQueryQuery,
    ObjectiveCardQueryQueryVariables,
    ObjectiveCardQueryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    ObjectiveCardQueryQuery,
    ObjectiveCardQueryQueryVariables,
    ObjectiveCardQueryProps<TChildProps>
  >(ObjectiveCardQueryDocument, {
    alias: 'withObjectiveCardQuery',
    ...operationOptions
  });
}
export const ObjectiveMaterialsQueryDocument = gql`
  query ObjectiveMaterialsQuery($objectiveId: Int!) {
    objective(id: $objectiveId) {
      id
      materials {
        id
        materialType
        relatedMaterials {
          id
          materialType
          title
          description
          resourcePath
        }
      }
    }
  }
`;

export const ObjectiveMaterialsQueryComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        ObjectiveMaterialsQueryQuery,
        ObjectiveMaterialsQueryQueryVariables
      >,
      'query'
    >,
    'variables'
  > & { variables: ObjectiveMaterialsQueryQueryVariables }
) => (
  <ReactApollo.Query<
    ObjectiveMaterialsQueryQuery,
    ObjectiveMaterialsQueryQueryVariables
  >
    query={ObjectiveMaterialsQueryDocument}
    {...props}
  />
);

export type ObjectiveMaterialsQueryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    ObjectiveMaterialsQueryQuery,
    ObjectiveMaterialsQueryQueryVariables
  >
> &
  TChildProps;
export function withObjectiveMaterialsQuery<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ObjectiveMaterialsQueryQuery,
    ObjectiveMaterialsQueryQueryVariables,
    ObjectiveMaterialsQueryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    ObjectiveMaterialsQueryQuery,
    ObjectiveMaterialsQueryQueryVariables,
    ObjectiveMaterialsQueryProps<TChildProps>
  >(ObjectiveMaterialsQueryDocument, {
    alias: 'withObjectiveMaterialsQuery',
    ...operationOptions
  });
}
export const ObjectiveSelectorQueryDocument = gql`
  query ObjectiveSelectorQuery($preschoolTestId: Int!) {
    preschoolTest(id: $preschoolTestId) {
      id
      title
      objectives {
        id
        title
      }
    }
  }
`;

export const ObjectiveSelectorQueryComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        ObjectiveSelectorQueryQuery,
        ObjectiveSelectorQueryQueryVariables
      >,
      'query'
    >,
    'variables'
  > & { variables: ObjectiveSelectorQueryQueryVariables }
) => (
  <ReactApollo.Query<
    ObjectiveSelectorQueryQuery,
    ObjectiveSelectorQueryQueryVariables
  >
    query={ObjectiveSelectorQueryDocument}
    {...props}
  />
);

export type ObjectiveSelectorQueryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    ObjectiveSelectorQueryQuery,
    ObjectiveSelectorQueryQueryVariables
  >
> &
  TChildProps;
export function withObjectiveSelectorQuery<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ObjectiveSelectorQueryQuery,
    ObjectiveSelectorQueryQueryVariables,
    ObjectiveSelectorQueryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    ObjectiveSelectorQueryQuery,
    ObjectiveSelectorQueryQueryVariables,
    ObjectiveSelectorQueryProps<TChildProps>
  >(ObjectiveSelectorQueryDocument, {
    alias: 'withObjectiveSelectorQuery',
    ...operationOptions
  });
}
export const AllObjectiveSelectorQueryDocument = gql`
  query AllObjectiveSelectorQuery {
    objectives {
      id
      title
    }
  }
`;

export const AllObjectiveSelectorQueryComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        AllObjectiveSelectorQueryQuery,
        AllObjectiveSelectorQueryQueryVariables
      >,
      'query'
    >,
    'variables'
  > & { variables?: AllObjectiveSelectorQueryQueryVariables }
) => (
  <ReactApollo.Query<
    AllObjectiveSelectorQueryQuery,
    AllObjectiveSelectorQueryQueryVariables
  >
    query={AllObjectiveSelectorQueryDocument}
    {...props}
  />
);

export type AllObjectiveSelectorQueryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    AllObjectiveSelectorQueryQuery,
    AllObjectiveSelectorQueryQueryVariables
  >
> &
  TChildProps;
export function withAllObjectiveSelectorQuery<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    AllObjectiveSelectorQueryQuery,
    AllObjectiveSelectorQueryQueryVariables,
    AllObjectiveSelectorQueryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    AllObjectiveSelectorQueryQuery,
    AllObjectiveSelectorQueryQueryVariables,
    AllObjectiveSelectorQueryProps<TChildProps>
  >(AllObjectiveSelectorQueryDocument, {
    alias: 'withAllObjectiveSelectorQuery',
    ...operationOptions
  });
}
export const ObjectiveSelectorOverviewQueryDocument = gql`
  query ObjectiveSelectorOverviewQuery(
    $schoolClassId: String
    $institutionId: String
  ) {
    preschoolTests {
      id
      orderValue
      title
      objectives {
        id
        title
        topic
        materials {
          id
          title
          materialType
          materialCategory
          resourcePath
        }
      }
    }
    schoolClass(id: $schoolClassId, institutionId: $institutionId) {
      id
      students {
        uniLoginId
        preschoolTestResults {
          resultGroups {
            languageProficiency
            preschoolTest {
              id
              title
              orderValue
            }
          }
        }
        resultConfirmations {
          languageProficiency
          preschoolTest {
            id
            title
            orderValue
          }
        }
        assessments {
          id
          attendanceStatus
          objectiveId
          isInvalidated
        }
      }
    }
  }
`;

export const ObjectiveSelectorOverviewQueryComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        ObjectiveSelectorOverviewQueryQuery,
        ObjectiveSelectorOverviewQueryQueryVariables
      >,
      'query'
    >,
    'variables'
  > & { variables?: ObjectiveSelectorOverviewQueryQueryVariables }
) => (
  <ReactApollo.Query<
    ObjectiveSelectorOverviewQueryQuery,
    ObjectiveSelectorOverviewQueryQueryVariables
  >
    query={ObjectiveSelectorOverviewQueryDocument}
    {...props}
  />
);

export type ObjectiveSelectorOverviewQueryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    ObjectiveSelectorOverviewQueryQuery,
    ObjectiveSelectorOverviewQueryQueryVariables
  >
> &
  TChildProps;
export function withObjectiveSelectorOverviewQuery<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ObjectiveSelectorOverviewQueryQuery,
    ObjectiveSelectorOverviewQueryQueryVariables,
    ObjectiveSelectorOverviewQueryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    ObjectiveSelectorOverviewQueryQuery,
    ObjectiveSelectorOverviewQueryQueryVariables,
    ObjectiveSelectorOverviewQueryProps<TChildProps>
  >(ObjectiveSelectorOverviewQueryDocument, {
    alias: 'withObjectiveSelectorOverviewQuery',
    ...operationOptions
  });
}
export const OtherMaterialsCardQueryDocument = gql`
  query OtherMaterialsCardQuery {
    materials {
      id
      title
      description
      materialType
      materialCategory
    }
  }
`;

export const OtherMaterialsCardQueryComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        OtherMaterialsCardQueryQuery,
        OtherMaterialsCardQueryQueryVariables
      >,
      'query'
    >,
    'variables'
  > & { variables?: OtherMaterialsCardQueryQueryVariables }
) => (
  <ReactApollo.Query<
    OtherMaterialsCardQueryQuery,
    OtherMaterialsCardQueryQueryVariables
  >
    query={OtherMaterialsCardQueryDocument}
    {...props}
  />
);

export type OtherMaterialsCardQueryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    OtherMaterialsCardQueryQuery,
    OtherMaterialsCardQueryQueryVariables
  >
> &
  TChildProps;
export function withOtherMaterialsCardQuery<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    OtherMaterialsCardQueryQuery,
    OtherMaterialsCardQueryQueryVariables,
    OtherMaterialsCardQueryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    OtherMaterialsCardQueryQuery,
    OtherMaterialsCardQueryQueryVariables,
    OtherMaterialsCardQueryProps<TChildProps>
  >(OtherMaterialsCardQueryDocument, {
    alias: 'withOtherMaterialsCardQuery',
    ...operationOptions
  });
}
export const PreviousAssessmentsQueryDocument = gql`
  query PreviousAssessmentsQuery(
    $uniLoginId: String!
    $schoolClassId: String!
    $institutionId: String!
  ) {
    student(
      uniLoginId: $uniLoginId
      classId: $schoolClassId
      institutionId: $institutionId
    ) {
      uniLoginId
      assessments {
        id
        evaluatedAt
        isInvalidated
        objectiveId
      }
    }
  }
`;

export const PreviousAssessmentsQueryComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        PreviousAssessmentsQueryQuery,
        PreviousAssessmentsQueryQueryVariables
      >,
      'query'
    >,
    'variables'
  > & { variables: PreviousAssessmentsQueryQueryVariables }
) => (
  <ReactApollo.Query<
    PreviousAssessmentsQueryQuery,
    PreviousAssessmentsQueryQueryVariables
  >
    query={PreviousAssessmentsQueryDocument}
    {...props}
  />
);

export type PreviousAssessmentsQueryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    PreviousAssessmentsQueryQuery,
    PreviousAssessmentsQueryQueryVariables
  >
> &
  TChildProps;
export function withPreviousAssessmentsQuery<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    PreviousAssessmentsQueryQuery,
    PreviousAssessmentsQueryQueryVariables,
    PreviousAssessmentsQueryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    PreviousAssessmentsQueryQuery,
    PreviousAssessmentsQueryQueryVariables,
    PreviousAssessmentsQueryProps<TChildProps>
  >(PreviousAssessmentsQueryDocument, {
    alias: 'withPreviousAssessmentsQuery',
    ...operationOptions
  });
}
export const SchoolCardQueryDocument = gql`
  query SchoolCardQuery($schoolInstitutionId: String) {
    school(id: $schoolInstitutionId) {
      id
      name
    }
  }
`;

export const SchoolCardQueryComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        SchoolCardQueryQuery,
        SchoolCardQueryQueryVariables
      >,
      'query'
    >,
    'variables'
  > & { variables?: SchoolCardQueryQueryVariables }
) => (
  <ReactApollo.Query<SchoolCardQueryQuery, SchoolCardQueryQueryVariables>
    query={SchoolCardQueryDocument}
    {...props}
  />
);

export type SchoolCardQueryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<SchoolCardQueryQuery, SchoolCardQueryQueryVariables>
> &
  TChildProps;
export function withSchoolCardQuery<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    SchoolCardQueryQuery,
    SchoolCardQueryQueryVariables,
    SchoolCardQueryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    SchoolCardQueryQuery,
    SchoolCardQueryQueryVariables,
    SchoolCardQueryProps<TChildProps>
  >(SchoolCardQueryDocument, {
    alias: 'withSchoolCardQuery',
    ...operationOptions
  });
}
export const SchoolClassAssessmentsQueryDocument = gql`
  query SchoolClassAssessmentsQuery(
    $schoolClassId: String!
    $institutionId: String!
  ) {
    schoolClass(id: $schoolClassId, institutionId: $institutionId) {
      id
      students {
        uniLoginId
        preschoolTestResults {
          resultGroups {
            languageProficiency
            preschoolTest {
              id
              title
              orderValue
            }
          }
        }
        resultConfirmations {
          languageProficiency
          preschoolTest {
            id
            title
            orderValue
          }
        }
        assessments {
          id
          attendanceStatus
          isInvalidated
          objectiveId
          scoreValue
        }
      }
    }
  }
`;

export const SchoolClassAssessmentsQueryComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        SchoolClassAssessmentsQueryQuery,
        SchoolClassAssessmentsQueryQueryVariables
      >,
      'query'
    >,
    'variables'
  > & { variables: SchoolClassAssessmentsQueryQueryVariables }
) => (
  <ReactApollo.Query<
    SchoolClassAssessmentsQueryQuery,
    SchoolClassAssessmentsQueryQueryVariables
  >
    query={SchoolClassAssessmentsQueryDocument}
    {...props}
  />
);

export type SchoolClassAssessmentsQueryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    SchoolClassAssessmentsQueryQuery,
    SchoolClassAssessmentsQueryQueryVariables
  >
> &
  TChildProps;
export function withSchoolClassAssessmentsQuery<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    SchoolClassAssessmentsQueryQuery,
    SchoolClassAssessmentsQueryQueryVariables,
    SchoolClassAssessmentsQueryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    SchoolClassAssessmentsQueryQuery,
    SchoolClassAssessmentsQueryQueryVariables,
    SchoolClassAssessmentsQueryProps<TChildProps>
  >(SchoolClassAssessmentsQueryDocument, {
    alias: 'withSchoolClassAssessmentsQuery',
    ...operationOptions
  });
}
export const SchoolClassListQueryDocument = gql`
  query SchoolClassListQuery {
    schools {
      id
      name
      schoolClasses {
        id
        schoolInstitutionId
        students {
          uniLoginId
        }
      }
    }
  }
`;

export const SchoolClassListQueryComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        SchoolClassListQueryQuery,
        SchoolClassListQueryQueryVariables
      >,
      'query'
    >,
    'variables'
  > & { variables?: SchoolClassListQueryQueryVariables }
) => (
  <ReactApollo.Query<
    SchoolClassListQueryQuery,
    SchoolClassListQueryQueryVariables
  >
    query={SchoolClassListQueryDocument}
    {...props}
  />
);

export type SchoolClassListQueryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    SchoolClassListQueryQuery,
    SchoolClassListQueryQueryVariables
  >
> &
  TChildProps;
export function withSchoolClassListQuery<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    SchoolClassListQueryQuery,
    SchoolClassListQueryQueryVariables,
    SchoolClassListQueryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    SchoolClassListQueryQuery,
    SchoolClassListQueryQueryVariables,
    SchoolClassListQueryProps<TChildProps>
  >(SchoolClassListQueryDocument, {
    alias: 'withSchoolClassListQuery',
    ...operationOptions
  });
}
export const SchoolClassPrioritizedAreaResultsQueryDocument = gql`
  query SchoolClassPrioritizedAreaResultsQuery(
    $schoolClassId: String!
    $institutionId: String!
  ) {
    schoolClass(id: $schoolClassId, institutionId: $institutionId) {
      id
      prioritizedAreaResults {
        score
        objective {
          title
          topic
          id
          linguisticCategory
        }
      }
      students {
        uniLoginId
        preschoolTestResults {
          resultGroups {
            languageProficiency
            preschoolTest {
              id
              title
              orderValue
            }
          }
        }
        resultConfirmations {
          languageProficiency
          preschoolTest {
            id
            title
            orderValue
          }
        }
        assessments {
          id
          attendanceStatus
          isInvalidated
          objectiveId
        }
      }
    }
  }
`;

export const SchoolClassPrioritizedAreaResultsQueryComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        SchoolClassPrioritizedAreaResultsQueryQuery,
        SchoolClassPrioritizedAreaResultsQueryQueryVariables
      >,
      'query'
    >,
    'variables'
  > & { variables: SchoolClassPrioritizedAreaResultsQueryQueryVariables }
) => (
  <ReactApollo.Query<
    SchoolClassPrioritizedAreaResultsQueryQuery,
    SchoolClassPrioritizedAreaResultsQueryQueryVariables
  >
    query={SchoolClassPrioritizedAreaResultsQueryDocument}
    {...props}
  />
);

export type SchoolClassPrioritizedAreaResultsQueryProps<
  TChildProps = {}
> = Partial<
  ReactApollo.DataProps<
    SchoolClassPrioritizedAreaResultsQueryQuery,
    SchoolClassPrioritizedAreaResultsQueryQueryVariables
  >
> &
  TChildProps;
export function withSchoolClassPrioritizedAreaResultsQuery<
  TProps,
  TChildProps = {}
>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    SchoolClassPrioritizedAreaResultsQueryQuery,
    SchoolClassPrioritizedAreaResultsQueryQueryVariables,
    SchoolClassPrioritizedAreaResultsQueryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    SchoolClassPrioritizedAreaResultsQueryQuery,
    SchoolClassPrioritizedAreaResultsQueryQueryVariables,
    SchoolClassPrioritizedAreaResultsQueryProps<TChildProps>
  >(SchoolClassPrioritizedAreaResultsQueryDocument, {
    alias: 'withSchoolClassPrioritizedAreaResultsQuery',
    ...operationOptions
  });
}
export const SchoolClassResultTabQueryDocument = gql`
  query SchoolClassResultTabQuery(
    $preschoolTestId: Int!
    $schoolClassId: String!
    $institutionId: String!
  ) {
    objectives {
      id
      materials {
        id
        materialType
        resourcePath
      }
    }
    preschoolTest(id: $preschoolTestId) {
      id
      objectives {
        id
      }
    }
    preschoolTests {
      id
      orderValue
      objectives {
        id
      }
    }
    prioritizedResult(
      preschoolTestId: $preschoolTestId
      classId: $schoolClassId
      institutionId: $institutionId
    ) {
      score
      objective {
        title
        topic
        id
        linguisticCategory
      }
    }
    schoolClass(id: $schoolClassId, institutionId: $institutionId) {
      id
      students {
        uniLoginId
        preschoolTestResults {
          resultGroups {
            languageProficiency
            preschoolTest {
              id
              title
              orderValue
            }
          }
        }
        resultConfirmations {
          languageProficiency
          preschoolTest {
            id
            title
            orderValue
          }
        }
        assessments {
          id
          attendanceStatus
          isInvalidated
          objectiveId
        }
      }
    }
  }
`;

export const SchoolClassResultTabQueryComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        SchoolClassResultTabQueryQuery,
        SchoolClassResultTabQueryQueryVariables
      >,
      'query'
    >,
    'variables'
  > & { variables: SchoolClassResultTabQueryQueryVariables }
) => (
  <ReactApollo.Query<
    SchoolClassResultTabQueryQuery,
    SchoolClassResultTabQueryQueryVariables
  >
    query={SchoolClassResultTabQueryDocument}
    {...props}
  />
);

export type SchoolClassResultTabQueryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    SchoolClassResultTabQueryQuery,
    SchoolClassResultTabQueryQueryVariables
  >
> &
  TChildProps;
export function withSchoolClassResultTabQuery<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    SchoolClassResultTabQueryQuery,
    SchoolClassResultTabQueryQueryVariables,
    SchoolClassResultTabQueryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    SchoolClassResultTabQueryQuery,
    SchoolClassResultTabQueryQueryVariables,
    SchoolClassResultTabQueryProps<TChildProps>
  >(SchoolClassResultTabQueryDocument, {
    alias: 'withSchoolClassResultTabQuery',
    ...operationOptions
  });
}
export const ShowLanguageGameButtonQueryDocument = gql`
  query ShowLanguageGameButtonQuery($objectiveId: Int) {
    objective(id: $objectiveId) {
      id
      materials {
        id
      }
    }
  }
`;

export const ShowLanguageGameButtonQueryComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        ShowLanguageGameButtonQueryQuery,
        ShowLanguageGameButtonQueryQueryVariables
      >,
      'query'
    >,
    'variables'
  > & { variables?: ShowLanguageGameButtonQueryQueryVariables }
) => (
  <ReactApollo.Query<
    ShowLanguageGameButtonQueryQuery,
    ShowLanguageGameButtonQueryQueryVariables
  >
    query={ShowLanguageGameButtonQueryDocument}
    {...props}
  />
);

export type ShowLanguageGameButtonQueryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    ShowLanguageGameButtonQueryQuery,
    ShowLanguageGameButtonQueryQueryVariables
  >
> &
  TChildProps;
export function withShowLanguageGameButtonQuery<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    ShowLanguageGameButtonQueryQuery,
    ShowLanguageGameButtonQueryQueryVariables,
    ShowLanguageGameButtonQueryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    ShowLanguageGameButtonQueryQuery,
    ShowLanguageGameButtonQueryQueryVariables,
    ShowLanguageGameButtonQueryProps<TChildProps>
  >(ShowLanguageGameButtonQueryDocument, {
    alias: 'withShowLanguageGameButtonQuery',
    ...operationOptions
  });
}
export const StudentListQueryDocument = gql`
  query StudentListQuery($schoolClassId: String, $institutionId: String) {
    schoolClass(id: $schoolClassId, institutionId: $institutionId) {
      id
      students {
        uniLoginId
        name
        position
        preschoolTestResults {
          resultGroups {
            languageProficiency
            preschoolTest {
              id
              title
              orderValue
            }
          }
        }
        resultConfirmations {
          languageProficiency
          preschoolTest {
            id
            title
            orderValue
          }
        }
        assessments {
          id
          attendanceStatus
          objectiveId
          scoreValue
          isInvalidated
        }
      }
    }
    preschoolTests {
      id
      orderValue
    }
  }
`;

export const StudentListQueryComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        StudentListQueryQuery,
        StudentListQueryQueryVariables
      >,
      'query'
    >,
    'variables'
  > & { variables?: StudentListQueryQueryVariables }
) => (
  <ReactApollo.Query<StudentListQueryQuery, StudentListQueryQueryVariables>
    query={StudentListQueryDocument}
    {...props}
  />
);

export type StudentListQueryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<StudentListQueryQuery, StudentListQueryQueryVariables>
> &
  TChildProps;
export function withStudentListQuery<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    StudentListQueryQuery,
    StudentListQueryQueryVariables,
    StudentListQueryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    StudentListQueryQuery,
    StudentListQueryQueryVariables,
    StudentListQueryProps<TChildProps>
  >(StudentListQueryDocument, {
    alias: 'withStudentListQuery',
    ...operationOptions
  });
}
export const StudentListItemQueryDocument = gql`
  query StudentListItemQuery(
    $uniLoginId: String!
    $schoolClassId: String!
    $institutionId: String!
  ) {
    student(
      uniLoginId: $uniLoginId
      classId: $schoolClassId
      institutionId: $institutionId
    ) {
      name
      uniLoginId
      assessments {
        id
        attendanceStatus
        objectiveId
        scoreValue
      }
    }
  }
`;

export const StudentListItemQueryComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        StudentListItemQueryQuery,
        StudentListItemQueryQueryVariables
      >,
      'query'
    >,
    'variables'
  > & { variables: StudentListItemQueryQueryVariables }
) => (
  <ReactApollo.Query<
    StudentListItemQueryQuery,
    StudentListItemQueryQueryVariables
  >
    query={StudentListItemQueryDocument}
    {...props}
  />
);

export type StudentListItemQueryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    StudentListItemQueryQuery,
    StudentListItemQueryQueryVariables
  >
> &
  TChildProps;
export function withStudentListItemQuery<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    StudentListItemQueryQuery,
    StudentListItemQueryQueryVariables,
    StudentListItemQueryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    StudentListItemQueryQuery,
    StudentListItemQueryQueryVariables,
    StudentListItemQueryProps<TChildProps>
  >(StudentListItemQueryDocument, {
    alias: 'withStudentListItemQuery',
    ...operationOptions
  });
}
export const StudentResultGroupQueryDocument = gql`
  query StudentResultGroupQuery(
    $uniLoginId: String!
    $schoolClassId: String!
    $institutionId: String!
  ) {
    student(
      classId: $schoolClassId
      uniLoginId: $uniLoginId
      institutionId: $institutionId
    ) {
      uniLoginId
      name
      position
      preschoolTestResults {
        resultGroups {
          languageProficiency
          preschoolTest {
            id
            title
            orderValue
          }
        }
      }
      resultConfirmations {
        languageProficiency
        preschoolTest {
          id
          title
          orderValue
        }
      }
    }
  }
`;

export const StudentResultGroupQueryComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        StudentResultGroupQueryQuery,
        StudentResultGroupQueryQueryVariables
      >,
      'query'
    >,
    'variables'
  > & { variables: StudentResultGroupQueryQueryVariables }
) => (
  <ReactApollo.Query<
    StudentResultGroupQueryQuery,
    StudentResultGroupQueryQueryVariables
  >
    query={StudentResultGroupQueryDocument}
    {...props}
  />
);

export type StudentResultGroupQueryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    StudentResultGroupQueryQuery,
    StudentResultGroupQueryQueryVariables
  >
> &
  TChildProps;
export function withStudentResultGroupQuery<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    StudentResultGroupQueryQuery,
    StudentResultGroupQueryQueryVariables,
    StudentResultGroupQueryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    StudentResultGroupQueryQuery,
    StudentResultGroupQueryQueryVariables,
    StudentResultGroupQueryProps<TChildProps>
  >(StudentResultGroupQueryDocument, {
    alias: 'withStudentResultGroupQuery',
    ...operationOptions
  });
}
export const StudentResultItemQueryDocument = gql`
  query StudentResultItemQuery(
    $uniLoginId: String!
    $schoolClassId: String!
    $institutionId: String!
  ) {
    student(
      uniLoginId: $uniLoginId
      classId: $schoolClassId
      institutionId: $institutionId
    ) {
      name
      uniLoginId
      languageProficiency
      preschoolTestResults {
        resultGroups {
          languageProficiency
          preschoolTest {
            id
            title
            orderValue
          }
        }
      }
      resultConfirmations {
        languageProficiency
        preschoolTest {
          id
          title
          orderValue
        }
      }
      assessments {
        id
        attendanceStatus
        objectiveId
        scoreValue
      }
    }
  }
`;

export const StudentResultItemQueryComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        StudentResultItemQueryQuery,
        StudentResultItemQueryQueryVariables
      >,
      'query'
    >,
    'variables'
  > & { variables: StudentResultItemQueryQueryVariables }
) => (
  <ReactApollo.Query<
    StudentResultItemQueryQuery,
    StudentResultItemQueryQueryVariables
  >
    query={StudentResultItemQueryDocument}
    {...props}
  />
);

export type StudentResultItemQueryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    StudentResultItemQueryQuery,
    StudentResultItemQueryQueryVariables
  >
> &
  TChildProps;
export function withStudentResultItemQuery<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    StudentResultItemQueryQuery,
    StudentResultItemQueryQueryVariables,
    StudentResultItemQueryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    StudentResultItemQueryQuery,
    StudentResultItemQueryQueryVariables,
    StudentResultItemQueryProps<TChildProps>
  >(StudentResultItemQueryDocument, {
    alias: 'withStudentResultItemQuery',
    ...operationOptions
  });
}
export const StudentResultListQueryDocument = gql`
  query StudentResultListQuery($schoolClassId: String, $institutionId: String) {
    schoolClass(id: $schoolClassId, institutionId: $institutionId) {
      id
      students {
        uniLoginId
        name
        position
        languageProficiency
        preschoolTestResults {
          resultGroups {
            languageProficiency
            preschoolTest {
              id
              title
              orderValue
            }
          }
        }
        resultConfirmations {
          languageProficiency
          preschoolTest {
            id
            title
            orderValue
          }
        }
        assessments {
          id
          attendanceStatus
          objectiveId
          scoreValue
        }
      }
    }
    preschoolTests {
      id
      orderValue
    }
  }
`;

export const StudentResultListQueryComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        StudentResultListQueryQuery,
        StudentResultListQueryQueryVariables
      >,
      'query'
    >,
    'variables'
  > & { variables?: StudentResultListQueryQueryVariables }
) => (
  <ReactApollo.Query<
    StudentResultListQueryQuery,
    StudentResultListQueryQueryVariables
  >
    query={StudentResultListQueryDocument}
    {...props}
  />
);

export type StudentResultListQueryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    StudentResultListQueryQuery,
    StudentResultListQueryQueryVariables
  >
> &
  TChildProps;
export function withStudentResultListQuery<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    StudentResultListQueryQuery,
    StudentResultListQueryQueryVariables,
    StudentResultListQueryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    StudentResultListQueryQuery,
    StudentResultListQueryQueryVariables,
    StudentResultListQueryProps<TChildProps>
  >(StudentResultListQueryDocument, {
    alias: 'withStudentResultListQuery',
    ...operationOptions
  });
}
export const StudentResultTabQueryDocument = gql`
  query StudentResultTabQuery(
    $uniLoginId: String!
    $schoolClassId: String!
    $institutionId: String!
  ) {
    objectives {
      id
      materials {
        id
        materialType
        materialCategory
      }
    }
    preschoolTests {
      id
      title
      orderValue
      objectives {
        id
      }
    }
    student(
      uniLoginId: $uniLoginId
      classId: $schoolClassId
      institutionId: $institutionId
    ) {
      uniLoginId
      preschoolTestResults {
        resultGroups {
          languageProficiency
          scoreGroups {
            scoreValue
            objectives {
              id
              title
              topic
            }
          }
          preschoolTest {
            id
            title
          }
          objectivesWithIllegalAbsence {
            id
            title
            topic
          }
          objectivesWithNoAssessment {
            id
            title
            topic
          }
        }
      }
      resultConfirmations {
        languageProficiency
        preschoolTest {
          id
          title
          orderValue
        }
      }
    }
  }
`;

export const StudentResultTabQueryComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        StudentResultTabQueryQuery,
        StudentResultTabQueryQueryVariables
      >,
      'query'
    >,
    'variables'
  > & { variables: StudentResultTabQueryQueryVariables }
) => (
  <ReactApollo.Query<
    StudentResultTabQueryQuery,
    StudentResultTabQueryQueryVariables
  >
    query={StudentResultTabQueryDocument}
    {...props}
  />
);

export type StudentResultTabQueryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    StudentResultTabQueryQuery,
    StudentResultTabQueryQueryVariables
  >
> &
  TChildProps;
export function withStudentResultTabQuery<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    StudentResultTabQueryQuery,
    StudentResultTabQueryQueryVariables,
    StudentResultTabQueryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    StudentResultTabQueryQuery,
    StudentResultTabQueryQueryVariables,
    StudentResultTabQueryProps<TChildProps>
  >(StudentResultTabQueryDocument, {
    alias: 'withStudentResultTabQuery',
    ...operationOptions
  });
}
export const StudentResultTipsAndTricksQueryDocument = gql`
  query StudentResultTipsAndTricksQuery(
    $uniLoginId: String!
    $schoolClassId: String!
    $institutionId: String!
  ) {
    student(
      uniLoginId: $uniLoginId
      classId: $schoolClassId
      institutionId: $institutionId
    ) {
      uniLoginId
      preschoolTestResults {
        resultGroups {
          preschoolTest {
            id
            title
          }
          scoreGroups {
            scoreValue
            objectives {
              id
              title
              topic
            }
          }
          objectivesWithIllegalAbsence {
            id
            title
            topic
          }
        }
      }
      resultConfirmations {
        languageProficiency
        preschoolTest {
          id
          orderValue
        }
      }
    }
  }
`;

export const StudentResultTipsAndTricksQueryComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        StudentResultTipsAndTricksQueryQuery,
        StudentResultTipsAndTricksQueryQueryVariables
      >,
      'query'
    >,
    'variables'
  > & { variables: StudentResultTipsAndTricksQueryQueryVariables }
) => (
  <ReactApollo.Query<
    StudentResultTipsAndTricksQueryQuery,
    StudentResultTipsAndTricksQueryQueryVariables
  >
    query={StudentResultTipsAndTricksQueryDocument}
    {...props}
  />
);

export type StudentResultTipsAndTricksQueryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    StudentResultTipsAndTricksQueryQuery,
    StudentResultTipsAndTricksQueryQueryVariables
  >
> &
  TChildProps;
export function withStudentResultTipsAndTricksQuery<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    StudentResultTipsAndTricksQueryQuery,
    StudentResultTipsAndTricksQueryQueryVariables,
    StudentResultTipsAndTricksQueryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    StudentResultTipsAndTricksQueryQuery,
    StudentResultTipsAndTricksQueryQueryVariables,
    StudentResultTipsAndTricksQueryProps<TChildProps>
  >(StudentResultTipsAndTricksQueryDocument, {
    alias: 'withStudentResultTipsAndTricksQuery',
    ...operationOptions
  });
}
export const TipsAndTricksQueryDocument = gql`
  query TipsAndTricksQuery($objectiveId: Int!) {
    objective(id: $objectiveId) {
      id
      tips {
        id
        description
        title
      }
    }
  }
`;

export const TipsAndTricksQueryComponent = (
  props: Omit<
    Omit<
      ReactApollo.QueryProps<
        TipsAndTricksQueryQuery,
        TipsAndTricksQueryQueryVariables
      >,
      'query'
    >,
    'variables'
  > & { variables: TipsAndTricksQueryQueryVariables }
) => (
  <ReactApollo.Query<TipsAndTricksQueryQuery, TipsAndTricksQueryQueryVariables>
    query={TipsAndTricksQueryDocument}
    {...props}
  />
);

export type TipsAndTricksQueryProps<TChildProps = {}> = Partial<
  ReactApollo.DataProps<
    TipsAndTricksQueryQuery,
    TipsAndTricksQueryQueryVariables
  >
> &
  TChildProps;
export function withTipsAndTricksQuery<TProps, TChildProps = {}>(
  operationOptions?: ReactApollo.OperationOption<
    TProps,
    TipsAndTricksQueryQuery,
    TipsAndTricksQueryQueryVariables,
    TipsAndTricksQueryProps<TChildProps>
  >
) {
  return ReactApollo.withQuery<
    TProps,
    TipsAndTricksQueryQuery,
    TipsAndTricksQueryQueryVariables,
    TipsAndTricksQueryProps<TChildProps>
  >(TipsAndTricksQueryDocument, {
    alias: 'withTipsAndTricksQuery',
    ...operationOptions
  });
}
